import { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Avatar,
  makeStyles,
  Chip,
  Badge,
} from '@material-ui/core';
import { getGameById } from '../../../api/cms/game';
import { Capitalize } from '../../../utils/string';
import useSWR from 'swr';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const _renderChipBool = (isTrue) => {
  if (isTrue) {
    return <Chip label="Yes" color="primary" />;
  } else {
    return <Chip label="None" color="default" />;
  }
};

const GameModal = ({ open = false, handleClose, game = {} }) => {
  useEffect(() => {
    return () => {};
  }, [game]);

  const classes = useStyles();

  const _renderGameDetails = (game) => {
    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem alignItems="center" divider>
            <Avatar
              src={game?.game_icon_url}
              variant="rounded"
              style={{ width: '100%', height: 'auto' }}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText>Category: {game?.game_category}</ListItemText>
          </ListItem>
          <ListItem divider>
            <ListItemText>Game Type: {game?.game_type}</ListItemText>
          </ListItem>
          <ListItem divider>
            <ListItemText>Game Code: {game?.game_code}</ListItemText>
          </ListItem>
          <ListItem divider>
            <ListItemText>Variant Type: {game?.variant_type}</ListItemText>
          </ListItem>
          <ListItem divider>
            <ListItemText>Game Release: {game?.game_created_time}</ListItemText>
          </ListItem>
          <ListItem divider>
            <ListItemText>New Game: {_renderChipBool(game?.is_new_game)}</ListItemText>
          </ListItem>
          <ListItem divider>
            <ListItemText>Top Pick: {_renderChipBool(game?.is_new_game)}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Details: </ListItemText>
          </ListItem>
          {game?.details?.map((v) => (
            <ListItem key={v}>
              <ListItemText>
                {Capitalize(v.Key)}: {v.Value}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <Container maxWidth={'sm'} fixed>
        <DialogTitle>
          {game?.game_name}{' '}
          <Chip
            color={game?.is_disabled ? 'default' : 'primary'}
            label={game?.is_disabled ? 'Disable' : 'Enable'}
          />
        </DialogTitle>
        <DialogContent>{_renderGameDetails(game)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export default GameModal;
