import ApplicationLayout from '../../components/layouts/ApplicationLayout';
import { getWinLostOverall, getWinLostByProvider } from '../../api/cms/summary';
import { LoadingBox } from '../../components/LoadingBox';
import useSWR from 'swr';
import { Paper, Typography, Button, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../components/Breadcrumbs';
import React from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import Thead from '../../components/table/Thead';
import TablePaginationActions from '../../components/table/TablePaginationActions';
import { RoyaltyFeeGroupPlatform as RoyaltyFeeGroup } from '../../components/summary/RoyaltyFeeGroup';
import {
  providerTableHeadUser as tableHead,
  headGroupTableUser as headGroupTable,
  overAllTableHeadUser as overAllTableHead,
  overAllHeadGroupTableUser as overAllHeadGroupTable,
} from '../../components/summary/thead-data';
import { genParamsQueryFromDateRange, useDateRange } from '../../utils/datetime';
import queryString from 'query-string';
import SummaryDateRangeContent from '../../components/summary/SummaryDateRangeContent';

const ByProvider = () => {
  const history = useHistory();
  const { search } = useLocation();
  const paramsString = queryString.parse(search);

  const [dateRange, setDateRange] = useDateRange(paramsString);

  const searchParam = React.useMemo(() => {
    return genParamsQueryFromDateRange(dateRange);
  }, [dateRange]);

  const onChangeDateRange = (range) => {
    setDateRange(range);
  };

  const { data, error } = useSWR(['overAllProvider', dateRange], () =>
    getWinLostOverall({ from_time: dateRange.startDateValue, to_time: dateRange.endDateValue }),
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { data: dataProvider, error: errorProvider } = useSWR(
    ['getProvider', page, rowsPerPage, dateRange],
    (url, page, limit) =>
      getWinLostByProvider({
        page: page + 1,
        limit,
        from_time: dateRange.startDateValue,
        to_time: dateRange.endDateValue,
      }),
  );
  const { logs = [], total = 0 } = dataProvider || {};

  if (error || errorProvider) toast.error('error');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <SummaryDateRangeContent
        onChange={onChangeDateRange}
        defaultValue={dateRange}
        value={dateRange}
      />
      {!data ? (
        <LoadingBox />
      ) : (
        <>
          <Box my={2}>
            <Typography variant="h6" gutterBottom component="div">
              System's Overall
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="win lost table" size="small">
                <Thead heads={overAllHeadGroupTable} align="center" />
                <Thead heads={overAllTableHead} align="center" />
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <RoyaltyFeeGroup data={row} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      {!logs ? (
        <LoadingBox />
      ) : (
        <>
          <Typography variant="h6" gutterBottom component="div">
            Provider's Win/Lose
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="win lost table">
              <Thead heads={headGroupTable} align="center" />
              <Thead heads={tableHead} align="center" />
              <TableBody>
                {logs.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Link to={`summary/provider/${row.provider_code}?${searchParam}`}>
                        {row.provider_name}
                      </Link>
                    </TableCell>
                    <RoyaltyFeeGroup data={row} />
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="center">รวม</TableCell>
                  <RoyaltyFeeGroup data={data?.[0] || []} disabledBg />
                </TableRow>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={9}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      )}
    </ApplicationLayout>
  );
};

export default ByProvider;
