import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <h2>
        No match found for <code>{location.pathname}</code>
      </h2>
      <Link to="/">
        <Button variant="contained" color="primary">
          BACK
        </Button>
      </Link>
    </Box>
  );
};

export default ErrorPage;
