import React from 'react';

import {
  formatISO9075,
  isDate,
  isEqual,
  addDays,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfToday,
  endOfToday,
  startOfTomorrow,
  startOfYesterday,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const getDefaultRanges = (date, tz) => {
  return [
    {
      label: 'Today',
      startDate: date,
      endDate: date,
      startDateValue: zonedTimeToUtc(startOfToday(), tz.data.tzCode),
      endDateValue: zonedTimeToUtc(startOfTomorrow(), tz.data.tzCode),
    },
    {
      label: 'Yesterday',
      startDate: addDays(date, -1),
      endDate: addDays(date, -1),
      startDateValue: zonedTimeToUtc(startOfYesterday(), tz.data.tzCode),
      endDateValue: zonedTimeToUtc(startOfToday(), tz.data.tzCode),
    },
    {
      label: 'This Month',
      startDate: startOfMonth(date),
      endDate: endOfMonth(date),
      startDateValue: zonedTimeToUtc(startOfMonth(date), tz.data.tzCode),
      endDateValue: zonedTimeToUtc(startOfMonth(addMonths(date, 1)), tz.data.tzCode),
    },
    {
      label: 'Last Month',
      startDate: startOfMonth(addMonths(date, -1)),
      endDate: endOfMonth(addMonths(date, -1)),
      startDateValue: zonedTimeToUtc(startOfMonth(addMonths(date, -1)), tz.data.tzCode),
      endDateValue: zonedTimeToUtc(startOfMonth(date), tz.data.tzCode),
    },
  ];
};

export const defaultRanges = (tz) => getDefaultRanges(new Date(), tz);

export const formatDateTime = (date) => {
  const datejs = new Date(date);
  if (!isDate(datejs)) return '';
  return formatISO9075(datejs);
};

export const formatDate = (date) => {
  // console.log('formatDate: ', date);
  const datejs = new Date(date);
  if (!isDate(datejs)) return '';
  return formatISO9075(datejs, { representation: 'date' });
};

export const genParamsQueryFromDateRange = (dateRange) => {
  // console.log('genParamsQueryFromDateRange: ', dateRange);
  var query = new URLSearchParams({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    startDateValue: dateRange.startDateValue,
    endDateValue: dateRange.endDateValue,
    // timezone: {
    //   data: {
    //     label: 'Asia/Singapore (GMT+08:00)',
    //     name: '(GMT+08:00) Singapore, Woodlands',
    //     tzCode: 'Asia/Singapore',
    //     utc: '+08:00',
    //   },
    //   name: '(GMT+08:00) Singapore, Woodlands',
    // },
  });
  return query.toString();
};

export const useDateRange = (paramsString) => {
  // console.log('paramsString: ', paramsString);
  const [dateRange, setDateRange] = React.useState({
    startDate: paramsString?.startDate
      ? zonedTimeToUtc(new Date(paramsString.startDate), 'Asia/Singapore')
      : zonedTimeToUtc(startOfDay(new Date()), 'Asia/Singapore'),
    endDate: paramsString?.endDate
      ? zonedTimeToUtc(new Date(paramsString.endDate), 'Asia/Singapore')
      : zonedTimeToUtc(new Date(), 'Asia/Singapore'),
    startDateValue: paramsString?.startDateValue
      ? zonedTimeToUtc(new Date(paramsString.startDateValue), 'Asia/Singapore')
      : zonedTimeToUtc(startOfDay(new Date()), 'Asia/Singapore'),
    endDateValue: paramsString?.endDateValue
      ? zonedTimeToUtc(new Date(paramsString.endDateValue), 'Asia/Singapore')
      : zonedTimeToUtc(startOfToday(), 'Asia/Singapore'),
  });

  const onsetDateRange = (date) => {
    // date.endDateValue = startOfDay(addDays(date.endDate, 1));
    setDateRange(date);
  };

  return [dateRange, onsetDateRange];
};
