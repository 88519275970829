export const platformBetLimitTableHead = [
  { name: '#' },
  { name: 'ID' },
  { name: 'Min - Max' },
  { name: 'Reference' },
];

export const platformAffiliateSettingHead = [
  { name: '#' },
  { name: 'Name' },
  { name: 'Category' },
  { name: 'Order' },
  { name: 'Enabled' },
  { name: 'Actions' },
];
