import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from '../store/user';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const [{ user, isLogin }] = useUser();
  // console.log('isLogin', isLogin, user);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isLogin) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />;
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(user.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }

        // authorised so return component
        return <Component {...routeProps} />;
      }}
    />
  );
};

export default PrivateRoute;
