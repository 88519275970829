import { useState, useEffect } from 'react';

export const useFetcher = (fetcher, payload) => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setResponse] = useState(null);

  useEffect(() => {
    fetcher(payload)
      .then((data) => {
        setResponse(data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetcher]);
  return { isLoading, error, data };
};
