import { MenuItem, Select } from '@material-ui/core';
const weekdays = [
  { id: 1, label: 'Monday' },
  { id: 2, label: 'Tuesday' },
  { id: 3, label: 'Wednesday' },
  { id: 4, label: 'Thursday' },
  { id: 5, label: 'Friday' },
  { id: 6, label: 'Saturday' },
  { id: 7, label: 'Sunday' },
];

const SelectWeekday = (props) => {
  const handleChange = (event) => {
    console.log(event.target.value, props.value);
    props.onChange(event.target.value);
  };

  return (
    <div>
      <Select
        id="select-weekday"
        onChange={(e) => handleChange(e)}
        defaultValue={'Select Weekday'}
        value={props.value}
      >
        <MenuItem value=""></MenuItem>
        {weekdays.map((v) => (
          <MenuItem value={v.id} key={v.id}>
            {v.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectWeekday;
