import axios from 'axios';
import _ from 'lodash';

export const getUserList = () => {
  return axios.get('/cms/user/list').then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data.users', []);
    }
  });
};

export const getUserById = (id) => {
  return axios.get(`/cms/user/${id}`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', null);
    }
  });
};
