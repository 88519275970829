import { Grid, TextField, Button } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateProviderById } from '../../../api/cms/provider';
import _ from 'lodash';
import FormProviderMaintenace from './ProviderMaintenance';

let schema = yup.object().shape({
  provider_name: yup.string().required(),
  provider_code: yup.string().required(),
  email: yup.string().email().required(),
  contact_person: yup.string().required(),
  note: yup.string(),
  phone_number: yup.string().required(),
});

const ProviderInfo = ({ providerInfo }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (values) => {
    console.log('values', values);
    let formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value);
    }
    updateProviderById(providerInfo.provider_id, formData)
      .then((data) => {
        toast.success('updated');
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="provider_name"
              defaultValue={providerInfo?.provider_name}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  label="Provider Name"
                  error={!!errors?.provider_name}
                  {...field}
                  helperText={errors?.provider_name?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="provider_code"
              defaultValue={providerInfo?.provider_code}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  label="Provider Code"
                  error={!!errors?.provider_code}
                  {...field}
                  helperText={errors?.provider_code?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="contact_person"
              defaultValue={providerInfo?.contact_person}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Contact Name"
                  type="text"
                  id="contact_person"
                  error={!!errors?.contact_person}
                  {...field}
                  helperText={errors?.contact_person?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="phone_number"
              defaultValue={providerInfo?.phone_number}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Contact Number"
                  type="text"
                  id="phone_number"
                  error={!!errors?.phone_number}
                  {...field}
                  helperText={errors?.phone_number?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="email"
              defaultValue={providerInfo?.email}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Email"
                  type="text"
                  id="email"
                  error={!!errors?.email}
                  {...field}
                  helperText={errors?.email?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="royalty_fee_percent"
              defaultValue={providerInfo?.royalty_fee_percent}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Royalty Fee (%)"
                  type="text"
                  error={!!errors?.royalty_fee_percent}
                  {...field}
                  helperText={errors?.royalty_fee_percent?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="note"
              defaultValue={providerInfo?.note}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Note"
                  type="text"
                  rows={3}
                  multiline
                  error={!!errors?.note}
                  {...field}
                  helperText={errors?.note?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid container item justifyContent="flex-end">
            <Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                margin="2px"
                disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <div>
        <Grid item xs={12}>
          <FormProviderMaintenace
          // open={true}
          // typeRisk={'close'}
          // period={period_code}
          />
        </Grid>
      </div>
    </div>
  );
};

export default ProviderInfo;
