import ApplicationLayout from '../../../../components/layouts/ApplicationLayout';
import CreateMaintenanceForm from '../../../../components/admin/provider/CreateMaintenanceForm';
import Breadcrumbs from '../../../../components/Breadcrumbs';

const MaintenanceNew = () => {
  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <CreateMaintenanceForm />
    </ApplicationLayout>
  );
};

export default MaintenanceNew;
