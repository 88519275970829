import {
  Button,
  Grid,
  Input,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Thead from 'components/table/Thead';
import { useEffect } from 'react';
import { useState } from 'react';
import { platformAffiliateSettingHead } from './thead-data';

const useStyles = makeStyles((theme) => ({
  box: {
    paddingInline: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    display: 'flex',
    background: 'white',
    alignItems: 'center',
  },
  gridLayout: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const PlatformUserProviderAffiliateSetting = ({ data = [] }) => {
  const [affiliateData, setAffiliateData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setAffiliateData([]);
    data.map((v) => {
      setAffiliateData([{ ...v, edit: false }]);
    });
    return () => {};
  }, []);

  const handleChange = (evt, onChangeData) => {
    const checked = evt.target.checked;
    setAffiliateData((prevState) =>
      prevState.map((v) => (v.category == onChangeData.category ? { ...v, enabled: checked } : v)),
    );
  };

  const onEdit = (isEditData) => {
    console.log(isEditData);
    setAffiliateData((prevState) =>
      prevState.map((v) => (v.category == isEditData.category ? { ...v, edit: !v.edit } : v)),
    );
  };

  console.log(affiliateData);

  const AffilateSettingTable = (affiliateData) => {
    return (
      <Table>
        <Thead heads={platformAffiliateSettingHead} align="center" />
        <TableBody>
          {affiliateData.map((v, i) => (
            <TableRow key={i}>
              <TableCell align="center">{i + 1}</TableCell>
              <TableCell align="center">{v.name}</TableCell>
              <TableCell align="center">{v.category}</TableCell>
              <TableCell align="center">
                {v.edit ? <Input defaultValue={v.order} type="number" /> : v.order}
              </TableCell>
              <TableCell align="center">
                {!v.enabled ? 'off' : ''}
                <Switch
                  checked={v.enabled}
                  name={`checked-${v.category}`}
                  color="secondary"
                  disabled={!v.edit}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={(evt) => handleChange(evt, v)}
                />
                {v.enabled ? 'on' : ''}
              </TableCell>
              <TableCell align="center">
                <Button color="secondary" variant="contained" onClick={() => onEdit(v)}>
                  {v.edit ? 'Update' : 'Edit'}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Grid className={classes.gridLayout}>
      <Typography variant="h6" gutterBottom component="div">
        Affiliate Settings
      </Typography>
      <Grid item xs={12}></Grid>
      {AffilateSettingTable(affiliateData)}
    </Grid>
  );
};

export default PlatformUserProviderAffiliateSetting;
