import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import {
  getWinLostOverallOfProvider,
  getWinLostByPlatform,
  getWinLossByPlatform,
  getWinLossOverallProvider,
} from '../../../api/cms/summary';
import { LoadingBox } from '../../../components/LoadingBox';
import useSWR from 'swr';
import { Paper, Typography, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { modifyArray } from '../../../utils/array';
import React from 'react';
import { genParamsQueryFromDateRange, useDateRange } from '../../../utils/datetime';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import Thead from '../../../components/table/Thead';
import TablePaginationActions from '../../../components/table/TablePaginationActions';
import RoyaltyFeeGroup from '../../../components/summary/RoyaltyFeeGroup';
import {
  platformTableHead as tableHead,
  headGroupTable,
  overAllTableHead,
  overAllHeadGroupTable,
  headPlaformGroupTable,
} from '../../../components/summary/thead-data';
import queryString from 'query-string';
import SummaryDateRangeContent from '../../../components/summary/SummaryDateRangeContent';

const ProviderDetail = () => {
  let { providerId } = useParams();
  let location = useLocation();
  const { search } = useLocation();
  const history = useHistory();
  let preventUrl = location.pathname
    .split('/')
    .filter((x) => x)
    .slice(0, 3)
    .join('/');

  const paramsString = queryString.parse(search);

  const [dateRange, setDateRange] = useDateRange(paramsString);

  const searchParam = React.useMemo(() => {
    return genParamsQueryFromDateRange(dateRange);
  }, [dateRange]);

  const onChangeDateRange = (range) => {
    setDateRange(range);
    const newSearchUrl = genParamsQueryFromDateRange(range);
    history.replace({
      pathname: '',
      search: `?${newSearchUrl}`,
      state: { isActive: true },
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  // getWinLossOverallProvider
  const { data, error } = useSWR(['overAll', providerId, dateRange], (url, provider_code) =>
    getWinLossOverallProvider({
      provider_code: provider_code,
      from_time: dateRange.startDateValue,
      to_time: dateRange.endDateValue,
    }),
  );

  // getWinLostByPlatform
  const { data: dataDetail, error: errorDetail } = useSWR(
    ['getPlatformByProvider', page, rowsPerPage, providerId, dateRange],
    (url, page, limit, providerId) =>
      getWinLossByPlatform({
        page: page + 1,
        limit,
        provider_code: providerId,
        from_time: dateRange.startDateValue,
        to_time: dateRange.endDateValue,
      }),
  );

  const dataOverAllArr = data ? [data] : [];
  const { logs = [], total = 0 } = dataDetail || {};

  if (error || errorDetail) toast.error('error');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const overAllGroupTable = React.useMemo(() => {
    if (!data) return overAllHeadGroupTable;
    return modifyArray(overAllHeadGroupTable, [
      { index: 3, name: `Provider (${data.provider_name})` },
    ]);
  }, [data]);

  const headGroupTableModify = React.useMemo(() => {
    if (!data) return headPlaformGroupTable;
    return modifyArray(headPlaformGroupTable, [
      { index: 4, name: `Provider (${data.provider_name})` },
    ]);
  }, [data]);

  console.log('parse data: ', data);
  return (
    <ApplicationLayout>
      <Breadcrumbs
        keepSearchParams
        breadcrumbNameAddOn={{
          [`${location.pathname}`]: `${data?.provider_name || ''} (Provider)`,
        }}
      />
      <SummaryDateRangeContent
        onChange={onChangeDateRange}
        defaultValue={dateRange}
        value={dateRange}
      />
      {!dataOverAllArr ? (
        <LoadingBox />
      ) : (
        <>
          <Box my={2}>
            <Typography variant="h6" gutterBottom component="div">
              {`${data?.provider_name}'s Overall`}
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="win lost table" size="small">
                <Thead heads={overAllGroupTable} align="center" />
                <Thead heads={overAllTableHead} align="center" />
                <TableBody>
                  {dataOverAllArr.map((row, index) => (
                    <TableRow key={index}>
                      <RoyaltyFeeGroup data={row} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      {!logs ? (
        <LoadingBox />
      ) : (
        <>
          <Typography variant="h6" gutterBottom component="div">
            Platform's Win/Lose
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="win lost table">
              <Thead heads={headGroupTableModify} align="center" />
              <Thead heads={tableHead} align="center" />
              <TableBody>
                {logs.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {/* <Link to={`${providerId}/platform/${row.platform_code}?${searchParam}`}> */}
                      {row.platform_name}
                      {/* </Link> */}
                    </TableCell>
                    <RoyaltyFeeGroup data={row} />
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="center">รวม</TableCell>
                  <RoyaltyFeeGroup data={dataOverAllArr?.[0] || []} disabledBg />
                </TableRow>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100, 250, 500, { label: 'All', value: 0 }]}
                    colSpan={9}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      )}
    </ApplicationLayout>
  );
};

export default ProviderDetail;
