import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 700,
    '&.lost': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    '&.win': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
  },
}));

const BalanceStatusChip = ({ label }) => {
  const classes = useStyles();

  return <Chip label={label} className={classnames(classes.root, label)} />;
};

export default BalanceStatusChip;
