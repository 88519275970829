import Breadcrumbs from 'components/Breadcrumbs';
import ApplicationLayout from 'components/layouts/ApplicationLayout';
import DialogPopup from 'components/admin/storage/DialogPopup';
import { useRouteMatch } from 'react-router';
import useSWR from 'swr';
import { CSSTransition } from 'react-transition-group';

import { getStorageFile } from 'api/cms/storage';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { LoadingBox } from 'components/LoadingBox';
import { Folder, Backup } from '@material-ui/icons';
import { useState } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    border: '2px solid #CCC',
    margin: '10px 15px',
    minHeight: '150px',
  },
  folder: {
    fontSize: '72px',
  },
  textEnd: {
    textAlign: 'end',
  },
  gap: {
    margin: '10px auto',
  },
  pointer: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  responsiveImg: {
    maxWidth: '100%',
    display: 'block',
  },
  imgBox: {
    margin: '10px 15px',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#303F9F',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#303F9F',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
}));

const Storage = () => {
  const { data, error, mutate } = useSWR(['getStorageFile'], () => getStorageFile());
  const [directory, setDirectory] = useState(['Root']);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [uploadOpened, setUploadOpened] = useState(false);
  const [files, setFiles] = useState([]);
  const [hasPopupOpen, setHasPopupOpen] = useState(false);
  const [info, setInfo] = useState({});
  const classes = useStyles();

  let match = useRouteMatch();

  const selectFolder = (v) => {
    let folder = v.toLocaleUpperCase();
    console.log(folder);
    setSelectedFolder(folder);
    setDirectory([...directory, folder]);
  };

  const _renderDirectory = () => {
    return directory.map((v, i) => (
      <>
        <span
          key={i}
          onClick={() => {
            i == 0 ? [setDirectory(['Root']), setSelectedFolder('')] : null;
          }}
          className={i == 0 ? classes.link : []}
        >
          {v}
        </span>
        <span className={classes.pointer}>
          {directory[directory.length - 1] == v ? '  ' : ' > '}
        </span>
      </>
    ));
  };

  const getInformation = (key) => {
    let info = data.files[selectedFolder.toLocaleLowerCase()].storage.filter((v) => v.key == key);
    setInfo(info[0]);
    handleOpenPopup();
  };

  const hadnleOpenedUploadFile = () => {
    setUploadOpened(!uploadOpened);
  };

  const handleClosePopup = (evt) => {
    console.log('handleClosePopup: ', evt);
    setHasPopupOpen(false);
  };

  const handleOpenPopup = () => {
    console.log('handleOpenPopup');
    setHasPopupOpen(true);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      // acceptedFiles.map((file) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    [files],
  );

  const contentValidate = (file) => {
    if (
      file.type != 'image/jpeg' &&
      file.type != 'image/jpg' &&
      file.type != 'image/png' &&
      file.type != 'image/gif'
    ) {
      return {
        code: 'type-image',
        message: 'required content-type image only',
      };
    }
  };

  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    validator: contentValidate,
    maxFiles: 1,
  });

  const reject = fileRejections.map(({ file, errors }) => {
    return (
      <ul key={file.name}>
        {errors.map((e) => (
          <li key={e.code} style={{ color: '#FF0000' }}>
            {e.message}
          </li>
        ))}
      </ul>
    );
  });

  const thumbs = files.map((file, i) => {
    return <img src={file.preview} key={i} className={classes.dropzoneimg} />;
  });

  // console.log('storage: ', data, data.files['']);
  return (
    <ApplicationLayout>
      {!data ? (
        <LoadingBox />
      ) : (
        <>
          <DialogPopup info={info} handleOpen={hasPopupOpen} handleClose={handleClosePopup} />
          <Breadcrumbs
            breadcrumbNameAddOn={{ [`${location.pathname}`]: 'Storage' }}
            renameByIndex={{ 1: 'Storage' }}
          />
          <Grid container justifyContent={'flex-end'}>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={() => hadnleOpenedUploadFile()}
            >
              <Backup /> &nbsp;UPLOAD FILE
            </Button>
          </Grid>
          <Grid container>
            <Grid item sm={6} justifyContent={'flex-end'}>
              <Typography variant={'subtitle1'}>{_renderDirectory()}</Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant={'subtitle1'} className={[classes.textEnd, classes.gap]}>
                Total:{' '}
                {selectedFolder != ''
                  ? data.files[selectedFolder.toLowerCase()] != 'etc'
                    ? data.files[selectedFolder.toLowerCase()]?.total
                    : data.files[`""`].total
                  : data.total}
              </Typography>
            </Grid>
          </Grid>
          {uploadOpened ? (
            <Grid container spacing={2} style={{ padding: 20 }}>
              <CSSTransition
                in={uploadOpened}
                timeout={300}
                classNames="animate-swipe"
                nodeRef={null}
                unmountOnExit
                onEnter={() => setUploadOpened(true)}
                onExited={() => setUploadOpened(false)}
              >
                <Grid item xs={12}>
                  <section className="container">
                    {reject}
                    <div {...getRootProps({ className: classes.dropzone })}>
                      <input {...getInputProps()} />
                      {files.length > 0
                        ? thumbs
                        : `Drag 'n' drop some files here, or click to select files`}
                    </div>
                  </section>
                </Grid>
              </CSSTransition>
            </Grid>
          ) : null}
          <Grid container>
            {selectedFolder == ''
              ? Object.keys(data?.files).map((value, idx) => {
                  let folder = value != '' ? value.toLocaleUpperCase() : 'ETC';
                  return (
                    <Grid
                      key={idx}
                      item
                      sm={3}
                      spacing={3}
                      onClick={() => selectFolder(folder)}
                      className={classes.pointer}
                    >
                      <Box textAlign={'center'} className={classes.box}>
                        <Folder color={'primary'} className={classes.folder} />
                        <Typography>{folder}</Typography>
                      </Box>
                    </Grid>
                  );
                })
              : data?.files[
                  selectedFolder.toLowerCase() != 'etc' ? selectedFolder.toLowerCase() : ''
                ]?.storage.map((v, idx) => {
                  let url = v.url;
                  let key = v.key;
                  return (
                    <Grid key={idx} item sm={3} spacing={3} className={classes.pointer}>
                      <Box textAlign={'center'} className={classes.imgBox}>
                        <img key={idx} src={url} className={classes.responsiveImg} />
                        <Button
                          color={'primary'}
                          variant={'outlined'}
                          fullWidth
                          onClick={() => getInformation(key)}
                        >
                          more info
                        </Button>
                      </Box>
                    </Grid>
                  );
                })}
          </Grid>
        </>
      )}
    </ApplicationLayout>
  );
};

export default Storage;
