import axios from 'axios';
import _ from 'lodash';

export const getDashboardReport = () => {
  // console.log('to_date : ', to_date);
  // console.log('from_date : ', from_date);
  return axios.get(`/cms/dashboard`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', {});
    }
  });
};
