import { Grid, TextField, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ApplicationLayout from '../components/layouts/ApplicationLayout';
import { getConfig, updateConfig } from '../api/config';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useFetcher } from '../hooks/useFetcher';
import { LoadingBox } from '../components/LoadingBox';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let schema = yup.object().shape({
  platform_access_token: yup.string().required(),
  user_info_url: yup.string().required(),
  bet_url: yup.string().required(),
  payout_url: yup.string().required(),
  rollback_url: yup.string().required(),
  callback_url: yup.string().required(),
});

const Profile = () => {
  const { data, error, isLoading } = useFetcher(getConfig);
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  if (error) toast.error('error');
  // console.log('data', data);

  const onSubmit = (values) => {
    console.log(values);
    updateConfig(values)
      .then((data) => {
        if (data?.code === 0) {
          toast.success('updated');
        } else {
          toast.error('error');
        }
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <ApplicationLayout>
      {!isLoading ? (
        <Container component="main" maxWidth="sm">
          <Typography variant="h6" gutterBottom component="div">
            Config
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="platform_access_token"
                  defaultValue={data?.platform_access_token}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Platform Access Token"
                      error={!!errors?.platform_access_token}
                      {...field}
                      helperText={errors?.platform_access_token?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="user_info_url"
                  defaultValue={data?.user_info_url}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="User Info Url"
                      type="text"
                      error={!!errors?.user_info_url}
                      {...field}
                      helperText={errors?.user_info_url?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="bet_url"
                  defaultValue={data?.bet_url}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Bet Url"
                      type="text"
                      id="bet_url"
                      error={!!errors?.bet_url}
                      {...field}
                      helperText={errors?.bet_url?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="payout_url"
                  defaultValue={data?.payout_url}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="PayOut Url"
                      type="text"
                      error={!!errors?.payout_url}
                      {...field}
                      helperText={errors?.payout_url?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="callback_url"
                  defaultValue={data?.callback_url}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Callback Url"
                      type="text"
                      error={!!errors?.callback_url}
                      {...field}
                      helperText={errors?.callback_url?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="rollback_url"
                  defaultValue={data?.rollback_url}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Rollback Url"
                      type="text"
                      error={!!errors?.rollback_url}
                      {...field}
                      helperText={errors?.rollback_url?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid container item justify="flex-end">
                <Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
                    className={classes.submit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <LoadingBox />
      )}
    </ApplicationLayout>
  );
};

export default Profile;
