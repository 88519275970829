import axios from 'axios';
import _ from 'lodash';

export const getGameById = (gameId) => {
  return axios.get(`/cms/game/${gameId}`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', null);
    }
  });
};

export const getGameVerified = () => {
  return axios.get(`/cms/game/not_verified`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', null);
    }
  });
};

export const updateGameVerified = (payload) => {
  return axios.post(`/cms/game/verified`, payload).then((res) => res.data);
  // (res) => {
  //   console.log('res:', res);
  //   return _.get(res, 'data.data', null);
  // },
  // (error) => {
  //   console.log('error:', error);
  //   return _.get(error, 'error', null);
  // },
  // );
};

export const deleteGameVerified = (payload) => {
  return axios.post(`/cms/game/delete`, payload).then((res) => res.data);
};

export const createGame = (payload, id) => {
  console.log('id 21 : ', id);
  return axios.post(`/cms/provider/${id}/game`, payload).then((res) => res.data);
};

export const updateGame = (payload, id, game) => {
  console.log('id 21 : ', id);
  return axios.put(`/cms/provider/${id}/game/${game}`, payload).then((res) => res.data);
};

export const deleteGame = (id) => {
  return axios.delete(`/cms/game/${id}`).then((res) => res.data);
};
