import {
  Grid,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  FormControl,
  Avatar,
  makeStyles,
  InputLabel,
  Button,
  Switch,
  Box,
  Typography,
} from '@material-ui/core';
import { Category } from '../../../utils/game_category';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateGame } from '../../../api/cms/game';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  cursor: {
    cursor: 'pointer',
  },
}));

const UpdateGameForm = ({ game }) => {
  const [state, setState] = useState({
    is_disabled: false,
    is_top_picked: false,
    is_new_game: false,
    is_maintenance: false,
  });

  const history = useHistory();
  let id = useParams();
  const swal = withReactContent(Swal);

  const [image, setImage] = useState('/images/provider/default.jpeg');
  const [preview, setPreview] = useState('/images/provider/default.jpeg');

  const handleChangeImage = (e) => {
    if (e.target.files.length > 0) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const onChangeDisable = (is_disabled) => {
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to ${is_disabled ? 'disabled' : 'enabled'}`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          console.log('isConfirm');
          updateGame({ is_disabled: is_disabled }, id.providerId, id.gameId)
            .then((data) => {
              if (data?.code == 0) {
                setState({ ...state, is_disabled: is_disabled });
                toast.success(`${is_disabled ? 'enabled' : 'disabled'} game success`);
                // history.push(`/provider_list/${id.providerId}`);
              } else {
                toast.error('disabled game failed: ', data.message);
              }
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
  };

  const onChangeMaintenance = (is_maintenance) => {
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to ${is_maintenance ? 'enabled' : 'disabled'} maintenance`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateGame({ is_maintenance: is_maintenance }, id.providerId, id.gameId)
            .then((data) => {
              console.log('data : ', data);
              if (data?.code == 0) {
                setState({ ...state, is_maintenance: is_maintenance });
                toast.success(`${is_maintenance ? 'enable' : 'disable'} maintenance game success`);
                // history.push(`/provider_list/${id.providerId}`);
              } else {
                toast.error('disabled game failed: ', data.message);
              }
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
  };

  const classes = useStyles();

  const schema = yup.object().shape({
    game_name: yup.string().required(),
    game_code: yup.string().required(),
    game_category: yup.string().required(),
    category: yup.string(),
  });

  const onSubmit = (values) => {
    let formData = new FormData(); //formdata object

    formData.append('game_name', values.game_name); //append the values with key, value pair
    formData.append('game_icon_url', image);
    formData.append('game_code', values.game_code);
    formData.append('game_type', values.game_type);
    formData.append('variant_type', values.variant_type);
    formData.append('is_top_picked', state.is_top_picked);
    // formData.append('is_disabled', state.is_disabled);
    formData.append('provider_game_id', values.provider_game_id);
    formData.append('is_new_game', state.is_new_game);
    formData.append('category', category);

    if (values.order != undefined) {
      let order = parseInt(values.order, 10);
      formData.append('order', order);
    }

    updateGame(formData, id.providerId, id.gameId)
      .then((data) => {
        if (data?.code == 0) {
          toast.success('update game success');
          history.push(`/provider_list/${id.providerId}`);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };
  const {
    control,
    setValue,
    formState: { errors },
    formState,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [category, setValueCategory] = useState('category');

  useEffect(() => {
    if (game) {
      setState({
        is_disabled: game.is_disabled,
        is_new_game: game.is_new_game,
        is_top_picked: game.is_top_picked,
        is_maintenance: game.is_maintenance,
        order: game.order,
      });
      setPreview(game.game_icon_url);
      setImage(game.game_icon_url);

      setValue('game_name', game.game_name);
      setValue('game_code', game.game_code);
      setValue('game_category', game.category);
      setValue('game_type', game.game_type);
      setValue('variant_type', game.variant_type);
      setValue('order', game.order);
      setValue('provider_game_id', game.provider_game_id);

      setValueCategory(game.category);
    }
    return () => {};
  }, [game]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Box alignItems="center">
            <FormControlLabel
              label={state.is_disabled ? 'Disable' : 'Enabled'}
              color="red"
              control={
                <Switch
                  checked={state.is_disabled}
                  onChange={(event) => {
                    onChangeDisable(event.target.checked);
                  }}
                  name="is_disabled"
                />
              }
              name="is_disabled"
              labelPlacement="start"
            />
          </Box>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <FormControlLabel
            label={state.is_maintenance ? 'Maintenance ON' : 'Maintenance OFF'}
            control={
              <Switch
                checked={state.is_maintenance}
                onChange={(event) => {
                  onChangeMaintenance(event.target.checked);
                }}
                name="is_maintenance"
              />
            }
            name="is_maintenance"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <input
            accept="image/*"
            name="game_icon_url"
            type="file"
            id="game_icon_url"
            hidden
            onChange={handleChangeImage}
          />
          <label htmlFor="game_icon_url">
            <Avatar src={preview} className={classes.large} variant="rounded" />
          </label>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="game_name"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Game Name"
                type="text"
                error={!!errors?.game_name}
                {...field}
                helperText={errors?.game_name?.message}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="game_code"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Game Code"
                type="text"
                disabled
                error={!!errors?.game_code}
                {...field}
                helperText={errors?.game_code?.message}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="game_category"
            render={({ option }) => (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">category</InputLabel>
                <Select
                  labelId="category"
                  variant="outlined"
                  fullWidth
                  label="Category"
                  value={category}
                  onChange={(event) => {
                    const name = event.target.value;
                    setValueCategory(name);
                  }}
                >
                  <option aria-label="None" value="category" selected />

                  {Category.map((value) => (
                    <option
                      // setcategory={value.category}
                      key={value.category}
                      value={value.category}
                      // value={value?.category_code}
                      onChange={(event) => {
                        console.log('event : ', event);
                        const category_code = event.target.value;
                        // const name = event.target.value;
                        setValueCategory(category_code);
                      }}
                      {...option}
                    >
                      {value.category}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="game_type"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Game Type"
                type="text"
                // error={!!errors?.username}
                {...field}
                // helperText={errors?.username?.message}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="variant_type"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Variant Type"
                type="text"
                // defaultValue={game?.game_code}
                // error={!!errors?.username}
                {...field}
                // helperText={errors?.username?.message}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="provider_game_id"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Provider Id"
                type="text"
                // defaultValue={game?.game_code}
                // error={!!errors?.username}
                {...field}
                // helperText={errors?.username?.message}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="is_new_game"
            render={({ field: { value } }) => (
              <FormControlLabel
                label="New Game"
                control={
                  <Checkbox
                    name="is_new_game"
                    color="primary"
                    checked={state.is_new_game}
                    onChange={handleChange}
                  />
                }
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="is_top_picked"
            render={({ field: { value } }) => (
              <FormControlLabel
                label="Top Pick"
                control={
                  <Checkbox
                    name="is_top_picked"
                    color="primary"
                    checked={state.is_top_picked}
                    onChange={handleChange}
                  />
                }
              />
            )}
            control={control}
          />
        </Grid>
        {state.is_top_picked ? (
          <Grid item xs={12} sm={6}>
            <Controller
              name="order"
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Order"
                  type="number"
                  error={!!errors?.order}
                  {...field}
                  helperText={errors?.order?.message}
                />
              )}
              control={control}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}></Grid>{' '}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          // disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
          className={classes.submit}
        >
          Save
        </Button>
      </Grid>
    </form>
  );
};

export default UpdateGameForm;
