import ApplicationLayout from '../../../../components/layouts/ApplicationLayout';
import CreateGameForm from '../../../../components/admin/provider/CreateGameForm';
import Breadcrumbs from '../../../../components/Breadcrumbs';

const GameNew = () => {
  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <CreateGameForm />
    </ApplicationLayout>
  );
};

export default GameNew;
