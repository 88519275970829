import React from 'react';
import { formatDateTime } from '../../utils/datetime';
import useDebounce from '../../hooks/useDebounce';
import { toast } from 'react-toastify';
import { getTransactionList } from '../../api/cms/summary';
import { LoadingBox } from '../LoadingBox';
import { Paper, Typography, Box, TextField, Grid } from '@material-ui/core';
import useSWR from 'swr';
import { modifyArray } from '../../utils/array';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import Thead from '../table/Thead';
import TablePaginationActions from '../table/TablePaginationActions';
import BalanceStatusChip from '../BalanceStatusChip';
import TransactionRoyaltyFeeGroup, {
  TransactionRoyaltyFeeGroupUser,
} from './TransactionRoyaltyFeeGroup';
import Cell from './Cell';
import {
  transactionTableHead as tableHead,
  transactionHeadGroupTable,
  transactionHeadGroupTableUser,
  transactionTableHeadUser,
} from './thead-data';
import { useParams } from 'react-router-dom';

const TransactionTable = ({ overAllData = {}, dateRange = {}, isUser = false }) => {
  let { platformId, providerId } = useParams();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchObj, setSearch] = React.useState({
    username: '',
  });
  const searchDebounce = useDebounce(searchObj, 700);

  const { data: dataDetail, error: errorDetail } = useSWR(
    ['getTransaction', page, rowsPerPage, platformId, providerId, dateRange, searchDebounce],
    (url, page, limit, platformId, providerId) =>
      getTransactionList({
        page: page + 1,
        limit,
        platform_code: platformId,
        provider_code: providerId,
        from_time: dateRange?.startDateValue,
        to_time: dateRange?.endDateValue,
        username: searchDebounce?.username || undefined,
      }),
    {
      revalidateOnFocus: false,
    },
  );
  const { logs, total = 0 } = dataDetail || {};

  if (errorDetail) toast.error('error');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const transactionHeadGroupTableByRole = isUser
    ? transactionHeadGroupTableUser
    : transactionHeadGroupTable;
  console.log('transactionHeadGroupTableByRole', transactionHeadGroupTableByRole);
  const headGroupTableModify = React.useMemo(() => {
    if (!overAllData) return transactionHeadGroupTableByRole;
    const tempHead = [];
    if (overAllData?.platform)
      tempHead.push({ index: 2, name: `Platform (${overAllData?.platform?.platform_name})` });
    if (overAllData?.provider && !isUser)
      tempHead.push({ index: 4, name: `Provider (${overAllData?.provider?.provider_name})` });
    return modifyArray(transactionHeadGroupTableByRole, tempHead);
  }, [overAllData]);

  if (!dataDetail) return <LoadingBox />;

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom component="div">
            Transaction
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            fullWidth
            id="username"
            label="Username"
            onChange={(e) => setSearch({ ...searchObj, username: e.target.value })}
            value={searchObj?.username}
            defaultValue={searchObj?.username}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="win lost table" size="small">
          <Thead heads={headGroupTableModify} align="center" />
          <Thead heads={isUser ? transactionTableHeadUser : tableHead} align="center" />
          <TableBody>
            {logs?.map((row, index) => (
              <TableRow key={index}>
                <Cell align="center">{row.player_username}</Cell>
                <Cell align="center">
                  <Box display="flex" flexDirection="column">
                    <small>
                      <strong>{formatDateTime(row.log_time)}</strong>
                    </small>
                    <small>Log Id: {`${row.log_id}`}</small>
                  </Box>
                </Cell>
                <Cell align="center">
                  <Box display="flex" flexDirection="column">
                    <small>
                      <strong>{row.game_id}</strong> ({row.provider_code})
                    </small>
                    <small>
                      Provider Transaction Id: {row?.detail?.providertransactionid || ''}
                    </small>
                  </Box>
                </Cell>
                <Cell align="center">
                  <BalanceStatusChip label={row.balance_status} />
                </Cell>
                {isUser ? (
                  <TransactionRoyaltyFeeGroupUser data={row} />
                ) : (
                  <TransactionRoyaltyFeeGroup data={row} />
                )}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={13}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default TransactionTable;
