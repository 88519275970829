import ApplicationLayout from '../../components/layouts/ApplicationLayout';
import { LoadingBox } from '../../components/LoadingBox';
import { Paper, Typography, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getTransactionOverAll } from '../../api/cms/summary';
import useSWR from 'swr';
import { modifyArray } from '../../utils/array';

import React from 'react';
import TransactionTable from '../../components/summary/TransactionTable';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
} from '@material-ui/core';
import Thead from '../../components/table/Thead';
import { RoyaltyFeeGroupPlatform as RoyaltyFeeGroup } from '../../components/summary/RoyaltyFeeGroup';
import {
  overAllTableHeadUser as overAllTableHead,
  overAllHeadGroupTableUser,
} from '../../components/summary/thead-data';
import queryString from 'query-string';
import SummaryDateRangeContent from '../../components/summary/SummaryDateRangeContent';
import { genParamsQueryFromDateRange, useDateRange } from '../../utils/datetime';

const Transaction = () => {
  let { platformId, providerId } = useParams();
  let location = useLocation();
  const { search } = useLocation();
  const history = useHistory();
  const paramsString = queryString.parse(search);

  const [dateRange, setDateRange] = useDateRange(paramsString);

  const onChangeDateRange = (range) => {
    setDateRange(range);
    const newSearchUrl = genParamsQueryFromDateRange(range);
    history.replace({
      pathname: '',
      search: `?${newSearchUrl}`,
      state: { isActive: true },
    });
  };

  const { data, error } = useSWR(
    ['overAll', platformId, providerId, dateRange],
    () =>
      getTransactionOverAll({
        platform_code: platformId,
        provider_code: providerId,
        from_time: dateRange.startDateValue,
        to_time: dateRange.endDateValue,
      }),
    {
      revalidateOnFocus: false,
    },
  );

  const dataOverAllArr = data ? [data] : [];
  if (error) toast.error('error');

  const overAllGroupTable = React.useMemo(() => {
    //user
    if (!data) return overAllHeadGroupTableUser;
    const tempHead = [];
    if (data?.platform)
      tempHead.push({ index: 1, name: `Platform (${data?.platform?.platform_name})` });
    return modifyArray(overAllHeadGroupTableUser, tempHead);
  }, [data]);

  return (
    <ApplicationLayout>
      <Breadcrumbs
        keepSearchParams
        breadcrumbNameAddOn={{
          [`${location.pathname}`]: data?.provider?.provider_name
            ? `${data?.provider?.provider_name} (Provider)`
            : 'Provider',
        }}
      />
      <SummaryDateRangeContent
        onChange={onChangeDateRange}
        defaultValue={dateRange}
        value={dateRange}
      />
      {!data ? (
        <LoadingBox />
      ) : (
        <>
          <Box my={2}>
            <Typography variant="h6" gutterBottom component="div">
              Overall
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="win lost table" size="small">
                <Thead heads={overAllGroupTable} align="center" />
                <Thead heads={overAllTableHead} align="center" />
                <TableBody>
                  {dataOverAllArr.map((row, index) => (
                    <TableRow key={index}>
                      <RoyaltyFeeGroup data={row} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      <TransactionTable overAllData={data} dateRange={dateRange} isUser />
    </ApplicationLayout>
  );
};

export default Transaction;
