import Cell from './Cell';
import { formatNumber, isNegativeNumber } from '../../utils/number';
import classnames from 'classnames';

const DiffProviderGroup = ({ data, isAdvance = false, disabledBg = false }) => {
  const bet_balance = parseFloat(data.p_diff_provider_bet_balance);
  const valid_bet_balance = parseFloat(data.p_diff_provider_valid_bet_balance);
  const win_loss_balance = parseFloat(data.p_diff_provider_win_loss_balance);
  const profit_loss_balance = parseFloat(data.p_diff_profit_loss_balance);
  return (
    <>
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(bet_balance) ? 'text-red' : 'text-blue',
          'underline',
        )}
        text={formatNumber(bet_balance)}
      />
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary underline',
          isNegativeNumber(valid_bet_balance) ? 'text-red' : 'text-blue',
          'underline',
        )}
        text={formatNumber(valid_bet_balance)}
      />
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(win_loss_balance) ? 'text-red' : 'text-blue',
          'underline',
        )}
        text={formatNumber(win_loss_balance)}
      />
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(profit_loss_balance) ? 'text-red' : 'text-blue',
          'underline',
        )}
        text={formatNumber(profit_loss_balance)}
      />
    </>
  );
};

export default DiffProviderGroup;
