import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const Thead = ({ heads, align = 'left' }) => {
  return (
    <TableHead>
      <TableRow>
        {heads.map((head, index) => (
          <TableCell
            key={index}
            align={head?.align || align}
            colSpan={head?.colspan}
            className={head?.className}
          >
            {head.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default Thead;
