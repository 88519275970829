import { format, parseISO } from 'date-fns';
import { Container, Grid, Icon, Paper, Typography, Link } from '@material-ui/core';
import Breadcrumbs from 'components/Breadcrumbs';
import ApplicationLayout from 'components/layouts/ApplicationLayout';

import { makeStyles } from '@material-ui/core/styles';
import { AttachMoneyOutlined, Person, ArrowForwardIos, Apartment } from '@material-ui/icons';
import { getDashboardReport } from '../api/cms/dashboard';
import { useUser } from '../store/user';
import useSWR from 'swr';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGlow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  gridLayout: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const DashboardPage = () => {
  const classes = useStyles();

  const [{ isLogin, user, isAdmin }, setUser] = useUser();

  const { data: data, error: error } = useSWR(['dashboard'], () => getDashboardReport(), {
    revalidateOnFocus: false,
    refreshInterval: 0,
    shouldRetryOnError: false,
  });

  let isLoading = !error && !data;

  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <Grid container spacing={3} className={classes.gridLayout}>
        {isAdmin ? (
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Typography variant={'subtitle1'} color={'textSecondary'}>
                Platform Today
              </Typography>
              <Typography variant={'h6'} color={'textSecondary'}>
                <Apartment color={'primary'} fontSize={'small'} /> {data?.new_platform_today || 0}
              </Typography>
              <Typography variant={'body1'} color={'textSecondary'}>
                This month
              </Typography>
            </Paper>
          </Grid>
        ) : null}
        <Grid item md={3} sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant={'subtitle1'} color={'textSecondary'}>
              Profit Today
            </Typography>
            <Typography variant={'h6'} color={'textSecondary'}>
              <AttachMoneyOutlined color={'primary'} fontSize={'small'} />
              {data?.profit_today || 0}
            </Typography>
            <Typography variant={'body1'} color={'textSecondary'}>
              This month
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant={'subtitle1'} color={'textSecondary'}>
              Active Player Today
            </Typography>
            <Typography variant={'h6'} color={'textSecondary'}>
              <Person color={'primary'} fontSize={'small'} /> {data?.player_active_today || 0}
            </Typography>
            <Typography variant={'body1'} color={'textSecondary'}>
              This month
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant={'subtitle1'} color={'textSecondary'}>
              New Player Today
            </Typography>
            <Typography variant={'h6'} color={'textSecondary'}>
              <Person color={'primary'} fontSize={'small'} /> {data?.player_today || 0}
            </Typography>
            <Typography variant={'body1'} color={'textSecondary'}>
              This month
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.gridLayout}>
        <Grid item md={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant={'subtitle1'} color={'textSecondary'}>
              Maintenance Announcement
            </Typography>
            {data?.maintenance.map((row, index) => (
              <p key={index} variant={'body'}>
                <span>
                  {format(parseISO(row.close_at), 'yyyy, LLL d')} {row.announcement}
                </span>
                <Link href={`/provider_list/${row.reference_id}`}>
                  <ArrowForwardIos color={'primary'} fontSize={'small'} />
                </Link>
              </p>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </ApplicationLayout>
  );
};

export default DashboardPage;
