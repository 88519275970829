import axios from 'axios';
import _ from 'lodash';

export const getPlatformList = () => {
  return axios.get('/cms/user/platform/list').then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data.users', []);
    }
  });
};

export const getPlatformDefaultList = () => {
  return axios.get('/cms/user/default/list').then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', []);
    }
  });
};

export const createPlatform = (payload) => {
  return axios.post(`/cms/user/platform`, payload).then((res) => res.data.data);
};

export const updatePlatformById = (id, payload) => {
  return axios.put(`/cms/user/platform/${id}`, payload).then((res) => res.data.data);
};

export const getProviderListByPlatformId = (id) => {
  return axios.get(`/cms/user/platform/${id}/provider/list`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data.providers', []);
    }
  });
};

export const getProviderByPlatformId = (platformId, providerId) => {
  return axios.get(`/cms/user/platform/${platformId}/provider/${providerId}`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', null);
    }
  });
};

export const createProviderByPlatformId = ({ platformId, providerId }, payload) => {
  return axios
    .post(`/cms/user/platform/${platformId}/provider/${providerId}`, payload)
    .then((res) => res.data.data);
};

export const updateProviderByPlatformId = ({ platformId, providerId }, payload) => {
  return axios
    .put(`/cms/user/platform/${platformId}/provider/${providerId}`, payload)
    .then((res) => res.data.data);
};

export const generateTokenByPlatformId = (platformId) => {
  return axios.get(`/cms/user/platform/${platformId}/generate_token`).then((res) => res.data.data);
};

export const callProviderUpdateBetLimit = ({ platformId, providerId }) => {
  return axios
    .get(`/cms/user/platform/${platformId}/provider/${providerId}/call_update_bet_limit`)
    .then((res) => res.data.data);
};

export const changePlatformBetLimit = ({ platformId, providerId }, payload) => {
  return axios
    .put(`/cms/user/platform/${platformId}/provider/${providerId}/bet_settings`, payload)
    .then((res) => res.data.data);
};
