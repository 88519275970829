import { getProviderList } from '../api/cms/provider';
import useSWR from 'swr';
import { Box, FormControl, Grid, InputLabel, makeStyles, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  layout: {
    maxWidth: '300px',
    width: '100%',
  },
}));

const DropdownProvider = (props) => {
  const { data: providers, error } = useSWR('provider', () => getProviderList());
  const [selectedProvider, setSelectedProvider] = useState('');
  const classes = useStyles();

  const handleChange = (event) => {
    setSelectedProvider(event.target.value);
    let p = providers.find((v) => v.provider_code == event.target.value);

    props.handleCallback({
      provider_code: p.provider_code,
      provider_id: p.provider_id,
      provider_name: p.provider_name,
    });
  };

  return (
    <>
      {providers ? (
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor={'select-provider'}>Select Provider</InputLabel>
            <Select
              value={selectedProvider}
              onChange={handleChange}
              inputProps={{
                name: 'select-provider',
                id: 'select-provider',
              }}
              defaultValue={''}
            >
              {providers.map((v, i) => (
                <option key={i} value={v.provider_code}>
                  {v.provider_name.toUpperCase()}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
    </>
  );
};

DropdownProvider.propTypes = {
  handleCallback: PropTypes.object,
};

export default DropdownProvider;
