import axios from 'axios';
import _ from 'lodash';

export const getUserPlayerListByPlatformCode = (
  id,
  search_user,
  from_date,
  to_date,
  page,
  perPage,
) => {
  console.log('to_date : ', to_date);
  console.log('from_date : ', from_date);
  return axios
    .get(
      `/cms/platform/${id}/player/list?search_user=${search_user}&to_time=${to_date}&from_time=${from_date}&page=${page}&limit=${perPage}`,
    )
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', {});
      }
    });
};

export const listUserPlayerOnlineByPlatformCode = (id) => {
  return axios.get(`/cms/platform/${id}/player/list?is_active=true`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', {});
    }
  });
};

export const getUserPlayerList = ({ page, limit, username, player_id }) => {
  return axios
    .get(`/cms/player/list`, {
      params: { page, limit, username, player_id },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', {});
      }
    });
};
