export const platformTableHead = [
  { name: 'Platform' },
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
  { name: 'Royalty Fee', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const userPlayerActiveTableHead = [
  { name: 'Current Active', className: 'bg-table-primary' },
  { name: 'New User This Week', className: 'bg-table-primary' },
];

export const providerTableHead = [
  { name: 'Provider' },
  { name: '', className: 'bg-table-secondary' },
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
  { name: 'Royalty Fee', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const groupUserTableHead = [
  { name: 'Username' },
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
  { name: 'Royalty Fee', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const providerTableHeadUser = [
  { name: 'Provider' },
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const headPlaformGroupTable = [
  { name: '', colspan: 1 },
  { name: 'ลูกค้า', colspan: 4, className: 'bg-table-secondary' },
  { name: 'Platform', colspan: 1, className: 'bg-table-primary' },
  { name: '9Coon (เรา)', colspan: 1, className: 'bg-table-secondary' },
  { name: 'Provider', colspan: 1, className: 'bg-table-primary' },
];

export const headGroupTable = [
  { name: '', colspan: 1 },
  { name: 'ลูกค้า', colspan: 5, className: 'bg-table-secondary' },
  { name: 'Platform', colspan: 1, className: 'bg-table-primary' },
  { name: '9Coon (เรา)', colspan: 1, className: 'bg-table-secondary' },
  { name: 'Provider', colspan: 1, className: 'bg-table-primary' },
];

export const headGroupTableUser = [
  { name: '', colspan: 1 },
  { name: 'ลูกค้า', colspan: 4, className: 'bg-table-secondary' },
  { name: 'Platform', colspan: 1, className: 'bg-table-primary' },
];

export const overAllHeadGroupTable = [
  { name: 'ลูกค้า', colspan: 4, className: 'bg-table-secondary' },
  { name: 'Platform', colspan: 1, className: 'bg-table-primary' },
  { name: '9Coon (เรา)', colspan: 1, className: 'bg-table-secondary' },
  { name: 'Provider', colspan: 1, className: 'bg-table-primary' },
];

export const overAllHeadGroupTableUser = [
  { name: 'ลูกค้า', colspan: 4, className: 'bg-table-secondary' },
  { name: 'Platform', colspan: 1, className: 'bg-table-primary' },
];

export const overAllTableHead = [
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
  { name: 'Royalty Fee', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const overAllTableHeadUser = [
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const transactionTableHead = [
  { name: 'สมาชิก' },
  { name: 'เวลาดำเนินการ' },
  { name: 'เกมส์' },
  { name: 'สถานะ' },
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee (%)', className: 'bg-table-primary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
  { name: 'Royalty Fee (%)', className: 'bg-table-secondary' },
  { name: 'Royalty Fee', className: 'bg-table-secondary' },
  { name: 'Royalty Fee (%)', className: 'bg-table-primary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const transactionTableHeadUser = [
  { name: 'สมาชิก' },
  { name: 'เวลาดำเนินการ' },
  { name: 'เกมส์' },
  { name: 'สถานะ' },
  { name: 'ยอดแทงรวม', className: 'bg-table-secondary' },
  { name: 'ยอดแทงจริง', className: 'bg-table-secondary' },
  { name: 'ยอดแพ้/ชนะ', className: 'bg-table-secondary' },
  { name: 'ยอดรวมสุทธิ', className: 'bg-table-secondary' },
  { name: 'Royalty Fee (%)', className: 'bg-table-primary' },
  { name: 'Royalty Fee', className: 'bg-table-primary' },
];

export const transactionHeadGroupTable = [
  { name: '', colspan: 4 },
  { name: 'ลูกค้า', colspan: 4, className: 'bg-table-secondary' },
  { name: 'Platform', colspan: 2, className: 'bg-table-primary' },
  { name: '9Coon (เรา)', colspan: 2, className: 'bg-table-secondary' },
  { name: 'Provider', colspan: 2, className: 'bg-table-primary' },
];
export const transactionHeadGroupTableUser = [
  { name: '', colspan: 4 },
  { name: 'ลูกค้า', colspan: 4, className: 'bg-table-secondary' },
  { name: 'Platform', colspan: 2, className: 'bg-table-primary' },
];
