import axios from 'axios';
import _ from 'lodash';

export const getStorageFile = () => {
  return axios.get('/cms/storage/list').then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', {});
    }
  });
};
