import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BreadcrumbsMaterial from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { useUser } from '../store/user';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const breadcrumbNameMap = {
  '/platform_list': 'Platform List',
  '/provider_list': 'Provider List',
  '/summary/provider': "Provider's Summary",
  '/summary/platform': "Platform's Summary",
  '/player_list': 'Player List',
  '/summary/users': "User's Summary",
  // '/game/new': "Platform's Summary",
};

const breadcrumbNameMapUser = {
  '/summary': 'Summary',
};

const LinkRouter = ({ children, to, ...rest }) => (
  <Link variant="body2" to={to} {...rest} component={RouterLink}>
    {children}
  </Link>
);

const Breadcrumbs = ({
  breadcrumbNameAddOn = {},
  renameByIndex = {},
  keepSearchParams = false,
}) => {
  const classes = useStyles();
  const [{ isAdmin }] = useUser();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const breadcrumbByRole = isAdmin ? breadcrumbNameMap : breadcrumbNameMapUser;
  const breadcrumbMap = { ...breadcrumbByRole, ...breadcrumbNameAddOn };
  const search = location.search;
  // console.log('breadcrumbMap', breadcrumbMap);
  // console.log('to', location);

  return (
    <div className={classes.root}>
      <BreadcrumbsMaterial
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <LinkRouter color="inherit" to="/">
          Home
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          if (!breadcrumbMap[to]) return;
          const searchUrl = keepSearchParams ? search : '';
          return last ? (
            <Typography color="textPrimary" variant="body2" key={to}>
              {renameByIndex[index] || breadcrumbMap[to]}
            </Typography>
          ) : (
            <LinkRouter color="inherit" to={`${to}${searchUrl}`} key={to}>
              {renameByIndex[index] || breadcrumbMap[to]}
            </LinkRouter>
          );
        })}
      </BreadcrumbsMaterial>
    </div>
  );
};

export default Breadcrumbs;
