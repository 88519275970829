import { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Container,
  Drawer,
  MenuItem,
  Menu,
  Button,
  AppBar,
  Typography,
  IconButton,
  Badge,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import {
  Menu as MenuIcon,
  Build,
  ChevronLeft,
  Inbox,
  Mail,
  ExitToApp,
  ChevronRight,
  Settings,
  Person,
  PersonAdd,
  Business,
  Games,
  Storage,
  Group,
} from '@material-ui/icons';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useLayout } from '../../store/layout';
import { useUser } from '../../store/user';
import AppMenuItem from './AppMenuItem';
import { ConfirmProvider } from 'material-ui-confirm';
import { getGameVerified } from 'api/cms/game';
import useSWR from 'swr';
import RepairWinLoss from 'pages/admin/RepairWinLoss';

const drawerWidth = 240;

const APP_VERSION = process.env.REACT_APP_VERSION;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentInner: {
    verticalAlign: 'middle',
  },
  logo: {
    width: 90,
  },
}));

const ApplicationLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  let location = useLocation();
  const [path, setPath] = useState('');

  const [{ open }, setLayout] = useLayout();
  const [{ isLogin, user, isAdmin, isCs }, setUser] = useUser();

  const { data: gameVerified, error } = useSWR('game_verified', () => getGameVerified());

  useEffect(() => {
    setPath(location.pathname);
    // console.log(location.pathname);
  }, [location, setPath]);

  const activetRoute = (route) => {
    return route === path;
  };

  const handleDrawerOpen = () => {
    setLayout({ open: true });
  };
  const handleDrawerClose = () => {
    setLayout({ open: false });
  };
  const logout = () => {
    setUser(null);
    // axios.defaults.headers.common['Authorization'] = '';
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log('gameVerified: ', );

  const menuUser = useMemo(() => {
    console.log('isCs', isCs);
    if (isAdmin)
      return [
        {
          name: 'Dashboard',
          link: '/',
          Icon: () => <p>DB</p>,
        },
        // {
        //   name: 'Game',
        //   Icon: () => <p>GE</p>,
        //   items: [

        //   ],
        // },
        {
          name: 'Platform',
          Icon: () => <p>PF</p>,
          items: [
            {
              name: 'Platform List',
              link: '/platform_list',
              Icon: Person,
            },
            {
              name: 'Player List',
              link: '/player_list',
              Icon: Person,
            },
          ],
        },
        {
          name: 'User',
          Icon: () => <p>U</p>,
          items: [
            {
              name: 'User List',
              link: '/user_list',
              Icon: PersonAdd,
            },
          ],
        },
        {
          name: 'Provider',
          Icon: () => <p>PV</p>,
          items: [
            {
              name: 'Provider List',
              link: '/provider_list',
              Icon: Business,
            },
          ],
        },
        {
          name: 'Win/Lose',
          Icon: () => <p>W/L</p>,
          items: [
            {
              name: 'By Platform',
              link: '/summary/platform',
              Icon: Person,
            },
            {
              name: 'By Provider',
              link: '/summary/provider',
              Icon: Business,
            },
            {
              name: 'By User',
              link: '/summary/users',
              Icon: Group,
            },
          ],
        },
        {
          name: 'Admin',
          link: '/',
          Icon: () => <p>ADM</p>,
          items: [
            {
              name: 'Storage',
              link: '/admin/storage',
              Icon: Storage,
            },
            {
              name: `Game Verified`,
              link: '/admin/game_verified',
              Icon: () => (
                <Badge badgeContent={gameVerified?.total || 0} color="error">
                  <Games />
                </Badge>
              ),
            },
            // {
            //   name: 'RepairWinLoss',
            //   link: '/admin/repair_win_loss',
            //   Icon: Build,
            // },
            {
              name: 'Routine Maintenance',
              link: '/admin/routine_maintenance',
              Icon: Build,
            },
          ],
        },
      ];
    else if (isCs)
      return [
        {
          name: 'Platform',
          Icon: () => <p>PF</p>,
          items: [
            {
              name: 'Player List',
              link: '/player_list',
              Icon: Person,
            },
          ],
        },
      ];
    else
      return [
        {
          name: 'Profile',
          link: '/profile',
          Icon: Person,
        },
        {
          name: 'Config',
          link: '/config',
          Icon: Settings,
        },
        {
          name: 'Win/Lose',
          link: '/summary',
          Icon: () => <span>W/L</span>,
        },
      ];
  }, [isAdmin]);

  return (
    <ConfirmProvider
      defaultOptions={{
        dialogProps: { maxWidth: 'xs' },
        confirmationButtonProps: { variant: 'contained' },
        cancellationButtonProps: { color: 'primary' },
      }}
    >
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classnames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classnames(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.content}>
              <span className={classes.contentInner}>
                <img src="/logo.png" className={classes.logo} />
                {/* {isAdmin ? '' : user?.platform_name || 'Platform'} */}
              </span>
            </div>
            {/* <Typography variant="h6" noWrap className={classes.content}>
              <img src="/logo.png" className={classes.logo} />
              <span>{isAdmin ? '9COON' : user?.platform_name || 'Platform'}</span>
            </Typography> */}
            {!isLogin ? (
              <Button variant="contained" size="small" color="secondary">
                Login
              </Button>
            ) : (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  variant="contained"
                  size="small"
                  color="secondary"
                >
                  {user.username}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={() => history.push('/profile')}>Profile</MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classnames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classnames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {menuUser.map((item, index) => (
              <AppMenuItem {...item} key={index} />
            ))}
            {/* {menuUser.map((menu, key) => (
            <ListItem
              button
              selected={activetRoute(menu.path)}
              component={Link}
              to={menu.path}
              key={key}
            >
              <ListItemIcon>{menu.iconComponent}</ListItemIcon>
              <ListItemText primary={menu.name} />
            </ListItem>
          ))} */}
            {/* {['topics'].map((text, index) => (
            <Link to={`/${text}`} key={text}>
              <ListItem button>
                <ListItemIcon>{index % 2 === 0 ? <Inbox /> : <Mail />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </Link>
          ))} */}
          </List>
          <Divider />
          {isLogin && (
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItem>
          )}
          <Divider />
          <ListItem>
            <ListItemText primary={`Version ${APP_VERSION}`} />
          </ListItem>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth={false} className={classes.container}>
            {children}
          </Container>
        </main>
      </div>
    </ConfirmProvider>
  );
};

export default ApplicationLayout;
