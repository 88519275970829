export const AllTimeZone = [
  {
    name: '(GMT+07:00) Bangkok',
    value: '(GMT+07:00) Bangkok, Hanoi, Haiphong, Samut Prakan, Mueang Nonthaburi',
    key: 'GMT+7',
  },
  {
    name: '(GMT+08:00) Singapore',
    value: '(GMT+08:00) Singapore, Woodlands',
    key: 'GMT+8',
  },
  {
    name: '(GMT-11:00)',
    value: '(GMT-11:00) Midway',
    key: 'GMT-11',
  },
  {
    name: '(GMT-10:00)',
    value: '(GMT-10:00) Adak',
    key: 'GMT-10',
  },
  {
    name: '(GMT-09:00)',
    value: '(GMT-09:00) Anchorage, Fairbanks, Eagle River, Badger, Knik-Fairview',
    key: 'GMT-09',
  },
  {
    name: '(GMT-08:00)',
    value: '(GMT-08:00) Vancouver, Surrey, Okanagan, Victoria, Burnaby',
    key: 'GMT-8',
  },
  {
    name: '(GMT-07:00)',
    value: '(GMT-07:00) Boise, Meridian, Nampa, Idaho Falls, Pocatello',
    key: 'GMT-7',
  },
  {
    name: '(GMT-06:00)',
    value: '(GMT-06:00) Mezcales, San Vicente, Bucerías, Valle de Banderas',
    key: 'GMT-6',
  },
  {
    name: '(GMT-05:00)',
    value: '(GMT-05:00) Atikokan',
    key: 'GMT-5',
  },
  {
    name: '(GMT-04:00)',
    value:
      '(GMT-04:00) The Valley, Blowing Point Village, Sandy Ground Village, The Quarter, Sandy Hill',
    key: 'GMT-4',
  },
  {
    name: '(GMT-03:00)',
    value: '(GMT-03:00) Palmas, Araguaína, Gurupi, Miracema do Tocantins, Porto Franco',
    key: 'GMT-3',
  },
  {
    name: '(GMT-02:00)',
    value: '(GMT-02:00) Itamaracá',
    key: 'GMT-2',
  },
  {
    name: '(GMT-01:00)',
    value: '(GMT-01:00) Scoresbysund',
    key: 'GMT-1',
  },
  {
    name: '(GMT+00:00)',
    value: '(GMT+00:00) Abidjan, Abobo, Bouaké, Daloa, San-Pédro',
    key: 'GMT0',
  },
  {
    name: '(GMT+01:00)',
    value: '(GMT+01:00) Algiers, Boumerdas, Oran, Tébessa, Constantine',
    key: 'GMT+1',
  },
  {
    name: '(GMT+02:00)',
    value: '(GMT+02:00) Lilongwe, Blantyre, Mzuzu, Zomba, Kasungu',
    key: 'GMT+2',
  },
  {
    name: '(GMT+03:00)',
    value: '(GMT+03:00) Asmara, Keren, Massawa, Assab, Mendefera',
    key: 'GMT+3',
  },
  {
    name: '(GMT+04:00)',
    value: '(GMT+04:00) Baku, Ganja, Sumqayıt, Lankaran, Yevlakh',
    key: 'GMT+4',
  },
  {
    name: '(GMT+05:00)',
    value: '(GMT+05:00) Mawson',
    key: 'GMT+5',
  },
  {
    name: '(GMT+06:00)',
    value: '(GMT+06:00) Vostok',
    key: 'GMT+6',
  },
];
