import { Fab, withStyles } from '@material-ui/core';
import { Grid, Typography, TextField, FormControlLabel, Switch, Button } from '@material-ui/core';
import { getMaintenanceList, updateMaintenanceByMaintenanceID } from '../../../api/cms/provider';
import useSWR from 'swr';
import { Add } from '@material-ui/icons';
import { render } from 'react-dom';
import React, { useState } from 'react';
import { Link, useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useEffect } from 'react';
import moment from 'moment';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ProviderMaintenace = () => {
  const [maintenanceID, setMaintenanceID] = useState();
  // const [selectedStartDate, handleDateChange] = useState(new Date());
  // const [selectedEndDate, handleEndDateChange] = useState(new Date());
  const { data, error } = useSWR(['getMaintenanceList', maintenanceID], () => getMaintenanceList());
  let id = useParams();

  // useEffect(() => {
  //   if (data.length > 0) {
  //   }
  // }, [data]);

  const formatTime = (timeStr) => {
    return timeStr.slice(0, 16);
  };

  const onActiveChange = (event, id) => {
    console.info('You clicked the switch.', event);
    console.info('ID.', id);

    var payload = {
      enabled: event,
    };
    // setMaintenanceID(id);
    updateMaintenanceByMaintenanceID(id, payload).then((data) => {
      console.log('data : ', data);
      if (data?.code == 0) {
        setMaintenanceID(id);
        toast.success('update success');
      } else {
        toast.error('error');
      }
    });
  };

  const onDateChange = (date, value) => {
    console.log('date: ', date, value);
    // return
  };
  let match = useRouteMatch();

  return (
    <Grid container style={{ margin: '50px auto' }}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom component="div">
          Provider Maintenance{'    '}
          <Link to={`${match.url}/maintenance/new`}>
            <Fab variant="circular" color="primary" size="medium">
              +
            </Fab>
          </Link>
        </Typography>
        <form>
          {data?.map((m, i) =>
            m.reference_id == id.providerId ? (
              <Grid key={m.id} container spacing={2}>
                <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        checked={m.enabled}
                        onChange={(event) => {
                          onActiveChange(event.target.checked, m.maintenance_id);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    label="Start Maintenance "
                    showTodayButton={true}
                    allowKeyboardControl={true}
                    format={'yyyy-MM-dd hh:mm'}
                    value={moment(m.close_at)}
                    onChange={onDateChange}
                    onError={console.log}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    label="End Maintenance "
                    showTodayButton={true}
                    allowKeyboardControl={true}
                    format={'yyyy-MM-dd hh:mm'}
                    value={moment(m.open_at)}
                    onChange={onDateChange}
                    onError={console.log}
                  />
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Announcement (ไทย)"
                    name="announcement_th"
                    value={m.announcement_th}
                    style={{ width: '80%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Announcement (Global)"
                    name="announcement"
                    value={m.announcement}
                    style={{ width: '80%' }}
                  />
                </Grid>
              </Grid>
            ) : null,
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default ProviderMaintenace;
