import { Breadcrumbs, Grid, makeStyles } from '@material-ui/core';
import ApplicationLayout from 'components/layouts/ApplicationLayout';
import SummaryDateRangeContent from 'components/summary/SummaryDateRangeContent';
import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { genParamsQueryFromDateRange } from 'utils/datetime';
import useSWR from 'swr';

const useStyle = makeStyles({
  container: {
    maxHeight: 500,
  },
});

const UserHistory = () => {
  const { search } = useLocation();
  // const paramString = queryString.parse(search);
  const [dateRange, setDateRange] = useState();
  const classes = useStyle();
  const paramsString = queryString.parse(search);

  const onChangeDateRange = (range) => {
    console.log('onChangeDateRange: ', range);
    setDateRange(range);
  };

  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <SummaryDateRangeContent
        onChange={onChangeDateRange}
        defaultValue={dateRange}
        value={dateRange}
      />
      <Grid container>
        <Grid item sm={12}>
          History
        </Grid>
      </Grid>
    </ApplicationLayout>
  );
};

export default UserHistory;
