import TableCell from '@material-ui/core/TableCell';
import classnames from 'classnames';

const Cell = ({ className, align, text, children, rowSpan }) => {
  return (
    <>
      <TableCell align={align || 'center'} className={className} rowSpan={rowSpan}>
        {children || text}
      </TableCell>
    </>
  );
};

export default Cell;
