import {
  atom,
  useRecoilState,
  selector,
  useResetRecoilState,
  useSetRecoilState,
  useRecoilValue,
} from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom: userPersist } = recoilPersist({ key: 'kk-user' });
const { persistAtom: tokenPersist } = recoilPersist({ key: 'kk-token' });
import _ from 'lodash';

export const user = atom({
  key: 'user',
  default: null,
  effects_UNSTABLE: [userPersist],
});

export const token = atom({
  key: 'token',
  default: null,
  effects_UNSTABLE: [tokenPersist],
});

export const userState = selector({
  key: 'userState',
  get: ({ get }) => {
    let userObj = get(user);
    let tokenObj = get(token);
    const isLogin = !!userObj;
    const isAdmin = _.get(userObj, 'user.role', 1) === 1000 ? true : false;
    const isCs = _.get(userObj, 'user.role', 1) === 3 ? true : false;
    return {
      user: _.get(userObj, 'user', null),
      token: tokenObj || null,
      isLogin,
      isAdmin,
      isCs,
    };
  },
  set: ({ set }, newValue) => {
    set(user, newValue);
    set(token, newValue?.token || null);
  },
});

export const useUser = () => useRecoilState(userState);
