import { CircularProgress, Box } from '@material-ui/core';

const LoadingBox = ({ size = 75 }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      flexGrow={1}
      style={{ margin: '15px auto' }}
    >
      <CircularProgress size={size} color="secondary" />
    </Box>
  );
};

export { LoadingBox };
