import axios from 'axios';

export const getProfile = () => {
  return axios.get('/user/info').then((res) => {
    // console.log('data profile', res.data.data);
    return res.data.data;
  });
};

export const updateProfile = (payload) => {
  return axios.put('/user/info', payload).then((res) => res.data.data);
};
