import { Grid, TextField, Button, Container, Typography, Avatar } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateProviderByPlatformId, createProviderByPlatformId } from '../../../api/cms/platform';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { format } from 'date-fns';
import DialogImageBox from '../../../components/ImageBox';

let schema = yup.object().shape({
  royalty_fee_percent: yup.number().integer().required(),
  note: yup.string(),
  order: yup.number().integer(),
  create_date: yup.string(),
  provider_logo: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const ProviderInfo = ({ providerInfo, platformId, providerId, providerActive }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));
  const [providerLogo, setProviderLogo] = useState('');
  const [file, setFile] = useState();
  // const handleChange = (values) => {
  //   console.log('handleChange ');
  // };
  const onSubmit = (values) => {
    if (providerActive !== undefined) {
      values['is_active'] = providerActive;
    }
    if (providerLogo !== '') {
      values['provider_logo'] = providerLogo;
    }
    console.log('file: ', file, values);

    if (file !== undefined) {
      console.log('file exist');
      let formData = new FormData();
      for (let k in values) {
        formData.append(k, values[k]);
      }
      console.log(formData);
      createProviderByPlatformId({ providerId, platformId }, formData)
        .then((data) => {
          toast.success('updated');
        })
        .catch((error) => {
          toast.error(_.get(error, 'response.data.message', 'error'));
        });
    } else {
      console.log(values);
      createProviderByPlatformId({ providerId, platformId }, values)
        .then((data) => {
          toast.success('updated');
        })
        .catch((error) => {
          toast.error(_.get(error, 'response.data.message', 'error'));
        });
    }

    // createProviderByPlatformId({ providerId, platformId }, values)
    //   .then((data) => {
    //     toast.success('updated');
    //   })
    //   .catch((error) => {
    //     toast.error(_.get(error, 'response.data.message', 'error'));
    //   });
  };

  const onChangeValueHandler = (val) => {
    setFile(val);
  };

  const onImageChangeHandler = (val) => {
    // console.log('provider_logo val: ', val);
    setProviderLogo(val);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} values={formState.dirtyFields}>
      <Grid container spacing={2}>
        <Grid item container justifyContent="center" alignItems="center">
          <DialogImageBox
            image={providerInfo?.provider_logo}
            onChangeValue={onChangeValueHandler}
            onImageChange={onImageChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="royalty_fee_percent"
            defaultValue={providerInfo?.royalty_fee_percent}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Royalty Fee (%)"
                type="text"
                error={!!errors?.royalty_fee_percent}
                {...field}
                helperText={errors?.royalty_fee_percent?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="start_date"
            defaultValue={
              providerInfo?.start_date
                ? format(new Date(providerInfo?.start_date), `yyyy-MM-dd'T'HH:mm:ssxxx`)
                : format(new Date(), `yyyy-MM-dd'T'HH:mm:ssxxx`)
            }
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Create Date"
                type="text"
                error={!!errors?.create_date}
                {...field}
                helperText={errors?.create_date?.message}
                disabled
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="note"
            defaultValue={providerInfo?.note}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Note"
                type="text"
                rows={3}
                multiline
                error={!!errors?.note}
                {...field}
                helperText={errors?.note?.message}
              />
            )}
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="order"
            defaultValue={providerInfo?.order_index}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Order"
                type="text"
                rows={3}
                error={!!errors?.order}
                {...field}
                helperText={errors?.order?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              values={formState.dirtyFields}
              disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProviderInfo;
