import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import { getProviderByPlatformId, updateProviderByPlatformId } from '../../../api/cms/platform';
import { getGameByProviderId } from '../../../api/cms/provider';
import { LoadingBox } from '../../../components/LoadingBox';
import ProviderInfo from '../../../components/admin/platform/ProviderInfo';
import useSWR from 'swr';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import TablePaginationActions from '../../../components/table/TablePaginationActions';
import Thead from '../../../components/table/Thead';
import {
  Paper,
  Button,
  IconButton,
  Collapse,
  Box,
  Typography,
  FormControlLabel,
  Switch,
  withStyles,
  Link,
  Badge,
  Grid,
  TextField,
  Checkbox,
  Tabs,
  Tab,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import FlagIcon from '@material-ui/icons/Flag';
import { useState } from 'react';
import classnames from 'classnames';
import { useMemo } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import _ from 'lodash';
import PlatformUserProviderMaxBetSetting from 'components/admin/platform/PlatformUserProviderMaxBetSetting';
import PlatformUserProviderAffiliateSetting from 'components/admin/platform/PlatformUserProviderAffiliateSetting';

const useRowStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
      cursor: 'pointer',
    },
    open: {
      backgroundColor: theme.palette.grey[300],
    },
  });
});

const useStyles = makeStyles((theme) => ({
  disabled: {
    filter: 'grayscale(100)',
    cursor: 'not-allowed',
  },
  box: {
    paddingInline: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    display: 'flex',
    background: 'white',
    alignItems: 'center',
  },
  gridLayout: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  'mx-2': {
    margin: '0 1rem',
  },
  'my-2': {
    margin: '1rem 0',
  },
  flex: {
    display: 'flex',
  },
  itemsCenter: {
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'end',
  },
}));

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={classnames(classes.root, { [classes.open]: open })}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.category}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.total}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {row.category}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Icon</TableCell>
                    <TableCell>Game Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>New game</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.games.map((game, index) => (
                    <TableRow key={`row-${index}`}>
                      <TableCell>
                        <img src={game.game_icon_url} height={30} />
                      </TableCell>
                      <TableCell>{game.game_name}</TableCell>
                      <TableCell>{game.game_type}</TableCell>
                      <TableCell>{game.game_category}</TableCell>
                      <TableCell>{game.is_new_game ? <FlagIcon color="error" /> : null}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="secondary">
                          <ExitToAppIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ProviderInfoWithGamePage = () => {
  let { providerId, platformId } = useParams();
  const [providerActive, setProviderActive] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();

  const { data: provider, error: errorProvider } = useSWR(
    ['providerById', providerId],
    (url, providerId) => getProviderByPlatformId(platformId, providerId),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  const { data: game = {}, error: errorGame } = useSWR(
    ['gameByProviderId', providerId, page, rowsPerPage],
    (url, providerId) =>
      getGameByProviderId({ providerId: providerId, page: page + 1, limit: rowsPerPage }),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  const { games = [], total = 0, current_page = 1 } = game;

  let isLoading = !errorProvider && !provider;
  let gameLoading = !errorGame && !game;

  if (errorProvider || errorGame) toast.error('error');

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const ProviderInfoContent = useMemo(() => {
    if (isLoading) return <LoadingBox />;
    if (provider) {
      setProviderActive(provider.is_active);
      return (
        <ProviderInfo
          providerInfo={provider}
          providerId={providerId}
          platformId={platformId}
          providerActive={provider.is_active}
        />
      );
    }
  }, [provider]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log('evt:', event);
    if (event.target.value == -1) {
      event.target.value = parseInt(0, 50);
      setRowsPerPage(0);
    } else {
      setRowsPerPage(parseInt(event.target.value, 50));
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onChangeActive = (is_active) => {
    updateProviderByPlatformId({ providerId, platformId }, { is_active })
      .then(() => {
        setProviderActive(is_active);
        toast.success(`${provider?.provider_name} is updated`);
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.provider.message', 'error'));
      });
  };

  const handleOpenGameDetail = (game_id) => {
    setOpen(true);
  };

  const handleCloseGameDetail = () => {
    setOpen(false);
  };

  const onTabChange = (event, newTab) => {
    setTabValue(newTab);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tab=${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  };

  // const handleEditPlatformGame = () => {

  // }

  return (
    <ApplicationLayout>
      <Box>
        <Breadcrumbs
          breadcrumbNameAddOn={{ [`${location.pathname}`]: provider?.provider_name }}
          renameByIndex={{ 1: 'Platform Info' }}
        />
        <Tabs value={tabValue} onChange={onTabChange}>
          <Tab label="General" value={0} {...a11yProps(0)} />
          <Tab label="Affiliate Setting" value={1} {...a11yProps(1)} />
          <Tab label="Min/Max Bet Setting" value={2} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom component="div">
              Provider Info {provider ? `(${provider?.provider_name})` : null}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={providerActive}
                  onChange={(event) => {
                    onChangeActive(event.target.checked);
                  }}
                />
              }
              label="Active"
            />
          </Box>
          {ProviderInfoContent}
          <Typography variant="h6" gutterBottom component="div">
            Game List
          </Typography>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <Grid className={classes.gridLayout}>
              <Grid item xs={12} className={classes.paper}>
                <Typography
                  gutterBottom
                  component="div"
                  className={(classes.flex, classes['mx-2'])}
                >
                  Filter
                </Typography>
                <Grid item xs={6}>
                  <Box className={classes.flex}>
                    <TextField
                      id="search"
                      label="Search Game name..."
                      variant="outlined"
                      size="small"
                      style={{ marginRight: '5px' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <FormControlLabel
                      control={<Checkbox name="maintenance" />}
                      label="Maintenance"
                    />
                    <FormControlLabel control={<Checkbox name="disabled" />} label="Disabled" />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table aria-label="game table" size="small">
                    <Thead
                      heads={[
                        { name: 'Name', align: 'left' },
                        { name: 'Category' },
                        { name: '' },
                        { name: 'Quick Action' },
                        { name: 'Action' },
                      ]}
                      align="center"
                    />
                    <TableBody>
                      {games.map((row) => (
                        <StyledTableRow key={row.game_id}>
                          <TableCell style={{ width: 'auto' }}>
                            <img
                              src={
                                row.game_icon_url
                                  ? row.game_icon_url
                                  : '/images/provider/default.jpeg'
                              }
                              className={classnames(row.is_disabled ? classes.disabled : '')}
                              style={{ width: '40px', padding: '0 5px' }}
                            />
                            {row.game_name} ({row.game_code})
                          </TableCell>
                          <TableCell style={{ width: 'auto' }} align="center">
                            {row.category}
                          </TableCell>
                          <TableCell align="center">
                            {row.is_new_game ? (
                              <>
                                <FiberNewIcon color={'primary'} />
                                <br />
                              </>
                            ) : null}
                            {row.is_disabled ? (
                              <Badge color={'error'} badgeContent="DISABLED" />
                            ) : null}
                          </TableCell>
                          <TableCell>{`${row.is_maintenance}`}</TableCell>
                          <TableCell align="center">
                            <Link onClick={() => handleOpenGameDetail(row.game_id)}>Edit</Link>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            { label: '50', value: 50 },
                            { label: '100', value: 100 },
                            { label: '150', value: 150 },
                            { label: 'All', value: 0 },
                          ]}
                          colSpan={3}
                          count={total}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <PlatformUserProviderAffiliateSetting data={provider?.affiliate_settings} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PlatformUserProviderMaxBetSetting data={provider} />
          {/* <Box style={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">
                  Bet Limit Setting
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Badge color="default" badgeContent="">
                  <div>Success</div>
                </Badge>
                <Button color={'primary'} variant="contained" className={classes['mx-2']}>
                  Update Player Bet Limit
                </Button>
              </Grid>
            </Grid>
          </Box> */}
        </TabPanel>
      </Box>
    </ApplicationLayout>
  );
};

export default ProviderInfoWithGamePage;
