import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import useSWR from 'swr';
import {
  getGameByProviderId,
  getCatagoryProvider,
  updateProviderConfig,
} from '../../../api/cms/provider';
import { getGameById } from '../../../api/cms/game';
import { LoadingBox } from '../../../components/LoadingBox';
import { toast } from 'react-toastify';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import TablePaginationActions from '../../../components/table/TablePaginationActions';
import Thead from '../../../components/table/Thead';
import GameModal from '../game/GameModal';
import {
  Badge,
  Grid,
  TextField,
  Collapse,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import { Category } from '../../../utils/game_category';
import MenuItem from '@material-ui/core/MenuItem';
import useDebounce from '../../../hooks/useDebounce';
import { ExpandMore, ExpandLess, GridOnRounded } from '@material-ui/icons';
import classnames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  disabled: {
    filter: 'grayscale(100)',
    cursor: 'not-allowed',
  },
  text: {
    fontWeight: 'normal',
    fontSize: '16px',
  },
  spacingSM: {
    padding: '0 0.25rem',
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const GameListTable = ({ providerId, categoryName, order, config }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [open, setOpen] = React.useState(false);
  const [game, setGame] = React.useState();
  const [category, setCategory] = React.useState(undefined);
  const [searchObj, setSearch] = React.useState({
    username: '',
  });
  const [getConfig, setConfig] = React.useState(config);
  const searchDebounce = useDebounce(searchObj, 700);
  let testData = [];
  let match = useRouteMatch();
  let cate = [];
  const { data = {}, error } = useSWR(
    ['gameByProviderId', providerId, page, rowsPerPage, searchDebounce, ''],
    (url, providerId) =>
      getGameByProviderId({
        providerId: providerId,
        page: page + 1,
        limit: rowsPerPage,
        search_game: searchDebounce?.username,
        category: categoryName,
      }),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );
  const { data: dataCategory, error: errorDataCategory } = useSWR(
    ['getCatagoryProvider', providerId],
    (url, providerId) => getCatagoryProvider(providerId),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );
  const [expanded, setExpanded] = React.useState(order == 0 ? true : false);
  const onCollapse = () => {
    setExpanded(!expanded);
  };

  if (dataCategory && data != undefined) {
    cate = dataCategory;
    for (var v = 0; v < dataCategory.length; v++) {
      let bbb = { category: cate[v], game: [] };
      testData.push(bbb);
    }
  }

  let isLoading = !error && !data;
  if (error) toast.error(error.error);
  const { games = [], total = 0 } = data;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
    if (event.target.value == 'All') {
      setCategory(undefined);
    } else {
      setCategory(event.target.value);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value == -1) {
      event.target.value = parseInt(0, 10);
      console.log('limit 0 : ', event.target.value);
      setRowsPerPage(0);
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };

  const handleOpenGameDetail = (gameId) => {
    setOpen(true);
    getGameById(gameId).then((data) => setGame(data));
  };

  const handleQuickAction = (gameId) => {};

  const handleCloseGameDetail = () => {
    setOpen(false);
    setGame({});
  };

  const handleChangeMaintenance = (evt) => {
    console.log(getConfig);
    const checked = evt.target.checked;
    Swal.fire({
      title: 'Are you sure',
      text: `Do you want to ${
        checked ? 'open' : 'close'
      } ${getConfig.category.toLowerCase()} maintenance`,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('changed');
        setConfig({ ...getConfig, is_maintenance: checked });
        const config = {
          category_id: getConfig.category_id,
          is_maintenance: checked,
        };
        updateProviderConfig(providerId, config).then((res) => {
          console.log(res);
          if (res.status === 200 && res.data.code === 0) {
            toast.success(`mantenance is ${checked ? 'on' : 'off'}`);
          }
        });
      }
    });
  };

  if (isLoading) return <LoadingBox />;
  return (
    <>
      <GameModal open={open} handleClose={handleCloseGameDetail} game={game} />
      <Card style={{ margin: '30px auto' }}>
        <CardHeader
          title={
            <>
              <Box>{categoryName.toUpperCase()}</Box>
              <Box>
                <span className={classes.text}>Maintenance</span>&nbsp;
                <span className={`${classes.text} ${classes.spacingSM}`}>
                  {getConfig.is_maintenance ? '' : 'off'}
                </span>
                <FormControlLabel
                  control={
                    <Switch
                      size="medium"
                      checked={getConfig.is_maintenance}
                      onChange={(e) => handleChangeMaintenance(e)}
                    />
                  }
                />
                <span className={`${classes.text} ${classes.spacingSM}`}>
                  {getConfig.is_maintenance ? 'on' : ''}
                </span>
              </Box>
            </>
          }
          action={
            <IconButton aria-label="collapse" onClick={onCollapse}>
              <ExpandMore />
            </IconButton>
          }
        />
        <Collapse in={expanded}>
          <CardContent spacing={1}>
            <Grid
              container
              justifyContent="flex-end"
              direction={'row'}
              item
              style={{ padding: '10px 0px' }}
            >
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                id="username"
                label="Search game name..."
                onChange={(e) => setSearch({ ...searchObj, username: e.target.value })}
                value={searchObj?.username}
                defaultValue={searchObj?.username}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="game table" size="small">
                  <Thead
                    heads={[
                      {
                        name: 'Image',
                        align: 'left',
                      },
                      { name: 'Name (Code)' },
                      { name: 'Category' },
                      { name: 'New Game' },
                      {
                        name: 'Actions',
                        align: 'right',
                      },
                      { name: '' },
                      { name: '' },
                    ]}
                    align="center"
                  />
                  <TableBody>
                    {games?.map((row, i) => (
                      <StyledTableRow key={i}>
                        <TableCell style={{ width: 'auto' }}>
                          <img
                            src={row.game_icon_url || '/images/provider/default.jpeg'}
                            className={classnames(row.is_disabled ? classes.disabled : '')}
                            style={{ width: '80px', padding: '0 5px' }}
                          />
                        </TableCell>
                        <TableCell style={{ width: 'auto' }} align="center">
                          {row.game_name} ({row.game_code})
                        </TableCell>
                        <TableCell style={{ width: 'auto' }} align="center">
                          {row.category}
                        </TableCell>
                        <TableCell align="center">
                          {row.is_new_game ? (
                            <>
                              <FiberNewIcon color="error" />
                              <br />
                            </>
                          ) : null}
                          {row.is_disabled ? (
                            <Badge color="secondary" badgeContent="Disabled" />
                          ) : null}
                        </TableCell>
                        <TableCell align="right">
                          <Link onClick={() => handleQuickAction(row.game_id)}>Quick</Link>
                        </TableCell>
                        <TableCell align="right">
                          <Link onClick={() => handleOpenGameDetail(row.game_id)}>View</Link>
                        </TableCell>
                        <TableCell align="left">
                          <Link href={`${match.url}/game/${row.game_id}/edit`}>Edit</Link>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                        colSpan={5}
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default GameListTable;
