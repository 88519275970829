import { defaultRanges } from '../utils/datetime';
import { Button, Box } from '@material-ui/core';

const DateRangeOptionButtonGroup = ({ onChange, tz }) => {
  const dropdownRange = defaultRanges(tz);
  return (
    <>
      {dropdownRange.map((value, key) => (
        <Box key={key} p={1}>
          <Button
            variant="contained"
            color="primary"
            key={key}
            onClick={() => onChange?.({ ...value })}
          >
            {value.label}
          </Button>
        </Box>
      ))}
    </>
  );
};

export default DateRangeOptionButtonGroup;
