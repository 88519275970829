import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import {
  getWinLostOverall,
  getWinLossOverall,
  getWinLostByPlatform,
  getWinLossByPlatform,
} from '../../../api/cms/summary';
import { LoadingBox } from '../../../components/LoadingBox';
import useSWR from 'swr';
import { Paper, Typography, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../../components/Breadcrumbs';
import React from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import Thead from '../../../components/table/Thead';
import TablePaginationActions from '../../../components/table/TablePaginationActions';
import RoyaltyFeeGroup from '../../../components/summary/RoyaltyFeeGroup';
import SummaryDateRangeContent from '../../../components/summary/SummaryDateRangeContent';
import {
  platformTableHead as tableHead,
  headPlaformGroupTable,
  overAllTableHead,
  overAllHeadGroupTable,
} from '../../../components/summary/thead-data';
import { genParamsQueryFromDateRange, useDateRange, useDateRangeBS } from '../../../utils/datetime';
import queryString from 'query-string';
import moment from 'moment';

const ByPlatform = () => {
  const history = useHistory();
  const { search } = useLocation();
  const paramsString = queryString.parse(search);

  const [dateRange, setDateRange] = useDateRange(paramsString);

  const searchParam = React.useMemo(() => {
    return genParamsQueryFromDateRange(dateRange);
  }, [dateRange]);

  const onChangeDateRange = (range) => {
    setDateRange(range);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const { data, error } = useSWR(
    ['overAllOfPlatform', dateRange],
    () =>
      getWinLossOverall({ from_time: dateRange.startDateValue, to_time: dateRange.endDateValue }),
    { refreshInterval: 0, revalidateOnFocus: false, shouldRetryOnError: false },
  );

  const { data: dataPlatform, error: errorPlatform } = useSWR(
    dateRange ? ['getPlatform', page, rowsPerPage, dateRange] : null,
    (url, page, limit) =>
      getWinLossByPlatform({
        page: page + 1,
        limit,
        from_time: dateRange.startDateValue,
        to_time: dateRange.endDateValue,
      }),
    { refreshInterval: 0, revalidateOnFocus: false, shouldRetryOnError: false },
  );
  const { logs = [], total = 0 } = dataPlatform || {};
  const [maxDate, setMaxDate] = React.useState(moment());
  const [minDate, setMinDate] = React.useState(moment().subtract(3, 'months'));

  if (error || errorPlatform) toast.error('error');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <SummaryDateRangeContent
        onChange={onChangeDateRange}
        defaultValue={dateRange}
        value={dateRange}
        // maxDate={maxDate}
        // minDate={minDate}
      />
      {!data ? (
        <LoadingBox />
      ) : (
        <>
          <Box my={2}>
            <Typography variant="h6" gutterBottom component="div">
              System's Overall
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="overall table" size="small">
                <Thead heads={overAllHeadGroupTable} align="center" />
                <Thead heads={overAllTableHead} align="center" />
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <RoyaltyFeeGroup data={row} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      {!logs ? (
        <LoadingBox />
      ) : (
        <>
          <Typography variant="h6" gutterBottom component="div">
            Platform's Win/Lose
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="win lost table">
              <Thead heads={headPlaformGroupTable} align="center" />
              <Thead heads={tableHead} align="center" />
              <TableBody>
                {logs.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Link to={`${row.platform_code}?${searchParam}`}>{row.platform_name}</Link>
                    </TableCell>
                    <RoyaltyFeeGroup data={row} />
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="center">รวม</TableCell>
                  <RoyaltyFeeGroup data={data?.[0] || []} disabledBg />
                </TableRow>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                    colSpan={12}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      )}
    </ApplicationLayout>
  );
};

export default ByPlatform;
