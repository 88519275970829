export const ProviderLinks = [
  {
    provider_code: 'kag',
    links: ['https://kareport.kaga88.com/backoffice/login.html'],
  },
  {
    provider_code: 'spg',
    links: ['http://backoffice.hugedolphin.com/login.jsp'],
  },
  {
    provider_code: 'jkg',
    links: ['http://www.partnerbo68.net'],
  },
  {
    provider_code: 'sag',
    links: ['https://bop.sa-bo.net/login'],
  },
  {
    provider_code: 'sxy',
    links: ['https://gciag.usplaynet.com'],
    prefix: '9cp',
  },
  {
    provider_code: 'kim',
    links: ['https://gciag.usplaynet.com'],
    prefix: 'k9p',
  },
  {
    provider_code: 'cqf',
    links: ['https://boof.cqgame.cc'],
  },
  {
    provider_code: 'cq9',
    links: ['https://boof.cqgame.cc'],
  },
  {
    provider_code: 'nsp',
    links: ['https://bo.goldpig88.com/login.jsp'],
  },
  {
    provider_code: 'pkg',
    links: ['https://www.pg-bo.net/#/login?code=zch259'],
  },
  {
    provider_code: 'pgs',
    links: ['https://ag.pgslot.co/login'],
  },
  {
    provider_code: 'pgl',
    links: [''],
  },
  {
    provider_code: 'dgg',
    links: ['https://ag.dg66.info/ag/login.html'],
    prefix: '',
  },
  {
    provider_code: 'ptg',
    links: ['https://aghippo168.com'],
    prefix: '',
  },
  {
    provider_code: 'ftg',
    links: ['https://9coon.funtagaming.info'],
    prefix: '',
  },
  {
    provider_code: 'big',
    links: ['http://xd111166.bgvip88.com'],
  },
  {
    provider_code: 'pmp',
    links: [
      'https://backoffice-sg9.ppgames.net',
      'https://casinoadmin.pragmaticplaylive.net/boui/auth/login',
    ],
  },
  {
    provider_code: 'spp',
    links: ['https://bo.sp-control.net'],
  },
  {
    provider_code: 'wml',
    links: ['https://ag.wm555.net'],
    prefix: 'wml_',
  },
  {
    provider_code: 'alb',
    links: ['https://ams.allbetgaming.net/#/login'],
    prefix: '',
  },
  {
    provider_code: 'ryc',
    links: ['http://ag.royalwinday.com/'],
    prefix: '9COONTHB06-',
  },
  {
    provider_code: 'rtg',
    links: ['http://admin88.ps9games.com/'],
    prefix: 'NCP',
  },
  {
    provider_code: 'neg',
    links: ['http://admin88.ps9games.com/'],
    prefix: 'NCP',
  },
  {
    provider_code: 'yeb',
    links: ['http://console.98kbus.top/#/e/account/player'],
    prefix: '',
  },
  {
    provider_code: 'ezg',
    links: ['https://bo.stagedc.net/'],
    prefix: '',
  },
  {
    provider_code: 'ezg',
    links: ['https://bo.stagedc.net/'],
    prefix: '',
  },
  {
    provider_code: 'sx2',
    links: ['https://gciag.usplaynet.com'],
    prefix: '9d0',
  },
  {
    provider_code: 'km2',
    links: ['https://gciag.usplaynet.com'],
    prefix: '9c0',
  },
];
