import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import { getProviderListByPlatformId } from '../../../api/cms/platform';
import { getUserById } from '../../../api/cms/user';
import { updatePlatformById } from '../../../api/cms/platform';
import { getCategoryByPlayformID, updateCategoryByPlayformID } from '../../../api/cms/category';

import { LoadingBox } from '../../../components/LoadingBox';
import PlatformInfo from '../../../components/admin/platform/PlatformInfo';
import useSWR from 'swr';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Paper,
  Button,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { toast } from 'react-toastify';
import { Link, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Breadcrumbs from '../../../components/Breadcrumbs';
import _ from 'lodash';
// import NumericInput from 'react-numeric-input';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  error: {
    border: 'red',
  },
}));

const PlatformInfoPage = () => {
  let { platformId } = useParams();
  let match = useRouteMatch();
  const [platformActive, setPlatformActive] = useState(false);
  const [demoActive, setDemoActive] = useState(false);

  const [valueCategory, setValueCategory] = useState(-1);
  const [updateStatus, setUpdateStatus] = useState();

  const classes = useStyles();

  let location = useLocation();

  const { data, error } = useSWR(
    ['getProviderListByPlatformId', platformId],
    (url, platformId) => getProviderListByPlatformId(platformId),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  let isLoading = !error && !data;

  const { data: userInfo, error: userError } = useSWR(
    ['getUserById', platformId],
    (url, platformId) => getUserById(platformId),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  const { data: categoryInfo, error: categoryError } = useSWR(
    [platformId],
    (platformId) => getCategoryByPlayformID(platformId),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  if (error || userError || categoryError) toast.error('error');

  const PlatformInfoContent = useMemo(() => {
    if (!userInfo && !userError) return <LoadingBox />;
    if (userInfo) {
      setPlatformActive(userInfo.is_active);
      setDemoActive(userInfo.demo_available);
      return <PlatformInfo platformInfo={userInfo} />;
    }
  }, [userInfo]);

  const onActiveChange = (is_active) => {
    updatePlatformById(platformId, { is_active })
      .then(() => {
        setPlatformActive(is_active);
        toast.success('updated');
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  const onDemoChange = (demo_available) => {
    console.log(demo_available);
    updatePlatformById(platformId, { demo_available })
      .then(() => {
        setDemoActive(demo_available);
        toast.success('updated');
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  const handleUpdate = (category) => {
    console.log('valueCategory : ', valueCategory);
    if (valueCategory != -1) {
      let formData = new FormData();
      formData.append('category', category);
      formData.append('order', valueCategory);
      updateCategoryByPlayformID(platformId, formData)
        .then(() => {
          toast.success('updated');
        })
        .catch((error) => {
          toast.error(_.get(error, 'response.data.message', 'error'));
        });
      setUpdateStatus(valueCategory);
      setValueCategory(-1);
    } else {
      toast.error('กรุณาเปลี่ยนลำดับ');
    }
  };

  return (
    <ApplicationLayout>
      <Box>
        <Breadcrumbs breadcrumbNameAddOn={{ [`${location.pathname}`]: userInfo?.platform_name }} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom component="div">
            Platform Info {userInfo ? `(${userInfo?.platform_name})` : null}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={platformActive}
                onChange={(event) => {
                  onActiveChange(event.target.checked);
                }}
              />
            }
            label="Active"
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={demoActive}
                onChange={(event) => {
                  onDemoChange(event.target.checked);
                }}
              />
            }
            label="Demo"
          />
        </Box>
        {PlatformInfoContent}
        <Typography variant="h6" gutterBottom component="div">
          Provider List
        </Typography>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell>Logo</TableCell>
                    <TableCell>Provider Name</TableCell>
                    <TableCell>Provider Royalty Fee</TableCell>
                    <TableCell>Royalty Fee</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{<img src={row.provider_logo} height={30} />}</TableCell>
                      <TableCell>{row.provider_name}</TableCell>
                      <TableCell>{row.royalty_provider_fee_percent}</TableCell>
                      <TableCell>{row.royalty_fee_percent}</TableCell>
                      <TableCell>
                        {row.is_active ? <CheckCircleIcon color="error" /> : null}
                      </TableCell>
                      <TableCell>
                        <Link to={`${match.url}/${row.provider_id}`}>
                          <Button variant="contained" color="secondary">
                            <ExitToAppIcon />
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
      <Box style={{ marginTop: '30px' }}>
        <Typography variant="h6" gutterBottom component="div">
          Category List
        </Typography>

        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell>Category Name</TableCell>
                    <TableCell>Order</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryInfo?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>
                        <TextField
                          type={'number'}
                          inputProps={{ pattern: '[0-9]{1,3}' }}
                          defaultValue={row.order}
                          onChange={(evt) => setValueCategory(evt.target.value)}
                        />
                      </TableCell>

                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleUpdate(row.category)}
                        >
                          {/* <ExitToAppIcon /> */}
                          Save
                        </Button>
                        {/* </Link> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </ApplicationLayout>
  );
};

export default PlatformInfoPage;
