import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  ButtonGroup,
  Box,
  Button,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { BackspaceOutlined, List, GridOn } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { getStorageFile } from '../api/cms/storage';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  backButton: {
    right: theme.spacing(1),
    textAlign: 'right',
  },
});

const useStyles = makeStyles((theme) => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#303F9F',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#303F9F',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  dropzoneimg: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    width: '25%',
  },
  large: {
    width: '100%',
    maxWidth: '320px',
    height: 'auto',
    cursor: 'pointer',
  },
  divider: {
    margin: '20px auto',
    height: 3,
  },
  media: {
    height: 150,
  },
  mediaList: {
    width: 150,
    height: 75,
  },
  content: {
    color: '#303F9F',
    textAlign: 'center',
    cursor: 'pointer',
  },
  rootList: {
    display: 'flex',
  },
  detailsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentList: {
    flex: '1 0 auto',
    color: '#303F9F',
    textAlign: 'center',
    cursor: 'pointer',
  },
  buttonActive: {
    background: '#303F9F',
    color: '#FFFFFF',
  },
  body: {
    color: '#303F9F',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 10,
  },
}));

const contentValidate = (file) => {
  if (
    file.type != 'image/jpeg' &&
    file.type != 'image/jpg' &&
    file.type != 'image/png' &&
    file.type != 'image/gif'
  ) {
    return {
      code: 'type-image',
      message: 'required content-type image only',
    };
  }
};

const DialogImageBox = ({ image, onChangeValue, onImageChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectFolder, setSelectFolder] = useState({ folder: '', open: false });
  const [layout, setLayout] = useState('grid');
  const [urlImage, setUrlImage] = useState(image);
  const [storageFiles, setStorageFiles] = useState({});
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const onDrop = useCallback(
    (acceptedFiles) => {
      // acceptedFiles.map((file) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    [files],
  );
  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    validator: contentValidate,
    maxFiles: 1,
  });

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file));
  }, [files]);

  const handleClickOpen = () => {
    setIsOpen(true);
    Promise.all([getStorageFile()]).then((result) => {
      setStorageFiles(result[0]?.files);
    });
  };

  const handleClose = (value) => {
    console.log('handleClose');
    setIsOpen(false);
  };

  const setUrl = (e) => {
    console.log('provider_logo url : ', e);
    setUrlImage(e);
    setStorageFiles([]);
    setIsOpen(false);
    onChangeValue(undefined);
    onImageChange(e);
  };

  const getFolder = (folder) => {
    setSelectFolder({ folder: folder, open: true });
  };

  const handleToggleLayout = (label) => {
    setLayout(label);
  };

  const acceptFile = () => {
    setUrlImage(files[0].preview);
    onChangeValue(files);
    onImageChange(files[0].preview);
    setFiles([]);
    setIsOpen(false);
  };

  const reject = fileRejections.map(({ file, errors }) => {
    return (
      <ul key={file.name}>
        {errors.map((e) => (
          <li key={e.code} style={{ color: '#FF0000' }}>
            {e.message}
          </li>
        ))}
      </ul>
    );
  });

  const thumbs = files.map((file, i) => {
    return (
      <img
        src={file.preview}
        key={i}
        className={classes.dropzoneimg}
        // onLoad={() => {
        //   URL.revokeObjectURL(file.preview);
        // }}
      />
    );
  });
  // const thumbs = acceptedFiles.map((file) => {
  //   setFiles(files, { preview: URL.createObjectURL(file) });
  //   if (files.length > 0) {
  //     console.log('file: ', file);
  //     return <img src={files[0].preview} key={file.path} className={classes.dropzoneimg} />;
  //   }
  // });

  const _renderCard = (data) => {
    return (
      <>
        <Grid container spacing={2} direction="row">
          <Grid item md={6} alignItems="center">
            <Box className={classes.body}>
              {selectFolder.open ? (
                <IconButton aria-label="back" onClick={() => setSelectFolder({ open: false })}>
                  <BackspaceOutlined />
                </IconButton>
              ) : null}
              {selectFolder.open ? selectFolder?.folder.toUpperCase() : 'Choose Folder'}
            </Box>
            {/* <Typography variant="h6">
              {selectFolder.open ? selectFolder?.folder.toUpperCase() : 'Choose Folder'}
            </Typography> */}
          </Grid>
          <Grid item md={6} alignItems="center">
            <Grid item justifyContent="flex-end" container>
              <ButtonGroup variant="outlined" color="primary">
                <IconButton
                  onClick={() => handleToggleLayout('grid')}
                  className={layout == 'grid' ? classes.buttonActive : ''}
                >
                  <GridOn />
                </IconButton>
                <IconButton
                  onClick={() => handleToggleLayout('list')}
                  className={layout == 'list' ? classes.buttonActive : ''}
                >
                  <List />
                </IconButton>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {!selectFolder.open
            ? Object.keys(data).map((k) =>
                layout == 'grid' ? (
                  <Grid item md={3} key={k} onClick={() => getFolder(k)}>
                    <Card>
                      <CardContent className={classes.content}>
                        <Typography variant="h6">{k || 'root'}</Typography>
                        {data[k].total}
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <Grid item xs={12} key={k} onClick={() => getFolder(k)}>
                    <Card>
                      <CardContent className={classes.content}>
                        <Typography variant="h6">{k || 'root'}</Typography>
                        {data[k].total}
                      </CardContent>
                    </Card>
                  </Grid>
                ),
              )
            : null}
        </Grid>
        {selectFolder.open ? (
          <>
            <Grid container spacing={2}>
              {(data[selectFolder.folder]?.storage || []).map((v, i) =>
                layout == 'grid' ? (
                  <Grid item md={3} key={i} onClick={() => setUrl(v.url)}>
                    <Card className={classes.content}>
                      <CardMedia className={classes.media} image={v.url} />
                      <CardContent>
                        {/* <Typography variant="h6">{k || 'root'}</Typography> */}
                        {v.key}
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <Grid item xs={12} key={i} onClick={() => setUrl(v.url)}>
                    <Card className={classes.rootList}>
                      <CardMedia className={classes.mediaList} image={v.url} />
                      <div className={classes.detailsList}>
                        <CardContent className={classes.contentList}>{v.key}</CardContent>
                      </div>
                    </Card>
                  </Grid>
                ),
              )}
            </Grid>
          </>
        ) : null}
      </>
    );
  };

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <>
      <img src={urlImage} className={classes.large} onClick={handleClickOpen} />
      <Dialog open={isOpen} onClose={handleClose} maxWidth={'md'} aria-labelledby="dialog-title">
        <DialogTitle dividers="true" onClose={handleClose} id="dialog-title">
          Choose Image
        </DialogTitle>
        <Grid container spacing={2} style={{ padding: 20 }}>
          <Grid item xs={12}>
            <section className="container">
              {reject}
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                {files.length > 0
                  ? thumbs
                  : `Drag 'n' drop some files here, or click to select files`}
              </div>
            </section>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button onClick={() => acceptFile()} variant="contained" color="primary">
              Confirm
            </Button>
          </Grid>
          <Divider className={classes.divider} />
          {_renderCard(storageFiles)}
        </Grid>
      </Dialog>
    </>
  );
};

export default DialogImageBox;
