import { Grid } from '@material-ui/core';
import { getProviderList } from '../../api/cms/provider';
import Breadcrumbs from 'components/Breadcrumbs';
import ApplicationLayout from 'components/layouts/ApplicationLayout';
import useSWR from 'swr';
import DropdownProvider from 'components/DropdownProvider';
import { useState } from 'react';

const RepairWinLoss = () => {
  const { data, error } = useSWR('provider', () => getProviderList());
  const [provider, setProvider] = useState({});
  console.log('repair win loss', data);

  const handleProviderChange = (evt) => {
    setProvider(evt);
  };

  return (
    <ApplicationLayout>
      <Breadcrumbs
        breadcrumbNameAddOn={{ [`${location.pathname}`]: 'Repair' }}
        renameByIndex={{ 1: 'Repair' }}
      />
      <Grid>
        <p>Select Provider</p>
        <DropdownProvider handleCallback={handleProviderChange} />
      </Grid>
    </ApplicationLayout>
  );
};

export default RepairWinLoss;
