import axios from 'axios';
import _ from 'lodash';

export const getCategoryByPlayformID = (user_id) => {
  return axios.get(`/cms/user/platform/${user_id}/game/category`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data.game_categories', null);
    }
  });
};

export const updateCategoryByPlayformID = (user_id, payload) => {
  return axios.post(`/cms/user/platform/${user_id}/game/category`, payload).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', null);
    }
  });
};

// export const getGameVerified = () => {
//   return axios.get(`/cms/game/not_verified`).then((res) => {
//     if (res.data.code === 0) {
//       return _.get(res, 'data.data', null);
//     }
//   });
// };

// export const createGame = (payload, id) => {
//   console.log('id 21 : ', id);
//   return axios.post(`/cms/provider/${id}/game`, payload).then((res) => res.data);
// };

// export const updateGame = (payload, id, game) => {
//   console.log('id 21 : ', id);
//   return axios.put(`/cms/provider/${id}/game/${game}`, payload).then((res) => res.data);
// };
