import CustomerGroup from './CustomerGroup';
import PlatformGroup from './PlatformGroup';
import ProviderGroup from './ProviderGroup';
import OurGroup from './OurGroup';
import classnames from 'classnames';

const TransactionRoyaltyFeeGroup = ({ data }) => {
  return (
    <>
      <CustomerGroup data={data} />
      <PlatformGroup data={data} isAdvance />
      <OurGroup data={data} isAdvance />
      <ProviderGroup data={data} isAdvance />
    </>
  );
};

export const TransactionRoyaltyFeeGroupUser = ({ data }) => {
  return (
    <>
      <CustomerGroup data={data} />
      <PlatformGroup data={data} isAdvance />
    </>
  );
};

export default TransactionRoyaltyFeeGroup;
