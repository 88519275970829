import {
  Box,
  Button,
  Grid,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import Breadcrumbs from 'components/Breadcrumbs';
import ApplicationLayout from 'components/layouts/ApplicationLayout';
import useSWR, { mutate } from 'swr';
import { toast } from 'react-toastify';
import { getRegularMaintenance } from '../../api/cms/maintenance';
import { Weekday } from 'utils/weekday';
import { useState, useEffect } from 'react';
import SelectWeekday from 'components/SelectWeekday';
import DropdownProvider from 'components/DropdownProvider';

const headers = [
  'Provider',
  'Start',
  'End',
  'Day',
  'Announce EN',
  'Announce TH',
  'Active',
  'Action',
];

const MaintenanceRoutine = () => {
  const { data, error } = useSWR('getRegularMaintenance', () => getRegularMaintenance());
  const [editMaintenance, setEditMaintenance] = useState(null);
  const [newMaintenance, setNewMaintenance] = useState(false);
  const [provider, setProvider] = useState();

  const handleEdit = (id) => {
    const m = data.find((v) => v.provider_id == id);
    setEditMaintenance(m);
  };
  const handleSave = (id) => {
    console.log(id);
    // mutate();
  };
  const handleChangeActive = (event, id) => {
    const checked = event.target.checked;
    console.log(checked, id);
    // mutate();
  };
  const handleChangeWeekday = (event) => {
    setEditMaintenance({ ...editMaintenance, daily_maintenance: event });
  };
  const handleChangeProvider = (event) => {
    setProvider(event);
  };

  if (!data && error) {
    return toast.error(error);
  }

  return (
    <ApplicationLayout>
      <Breadcrumbs
        breadcrumbNameAddOn={{ [`${location.pathname}`]: 'Routine Maintenance' }}
        renameByIndex={{ 1: 'Routine Maintenance' }}
      />
      <Grid spacing={2}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => {
            setNewMaintenance(true);
          }}
        >
          <span>New +</span>
        </Button>
      </Grid>
      <Grid>
        <Table size="small" style={{ margin: '15px auto' }}>
          <TableHead>
            <TableRow>
              {headers.map((head, i) => {
                return <TableCell key={i}>{head}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((m, i) =>
              editMaintenance && editMaintenance.provider_id == m.provider_id ? (
                <TableRow key={i}>
                  <TableCell>{editMaintenance.provider_code.toUpperCase()}</TableCell>
                  <TableCell>
                    <TextField
                      id="starttime"
                      type="time"
                      value={editMaintenance.start_maintenance}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        setEditMaintenance({
                          ...editMaintenance,
                          start_maintenance: event.target.value,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="starttime"
                      type="time"
                      value={editMaintenance.end_maintenance}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        setEditMaintenance({
                          ...editMaintenance,
                          end_maintenance: event.target.value,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <SelectWeekday
                      value={editMaintenance.daily_maintenance}
                      onChange={(e) => handleChangeWeekday(e)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      type="text"
                      value={editMaintenance.announcement}
                      onChange={(event) => {
                        console.log(event.target.value);
                        setEditMaintenance({
                          ...editMaintenance,
                          announcement: event.target.value,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      type="text"
                      value={editMaintenance.announcement_th}
                      onChange={(event) => {
                        setEditMaintenance({
                          ...editMaintenance,
                          announcement_th: event.target.value,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={m.is_active}
                      onChange={(event) => handleChangeActive(event, m.provider_id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSave(m.provider_id)}
                    >
                      Save
                    </Button>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={i}>
                  <TableCell>{m.provider_code.toUpperCase()}</TableCell>
                  <TableCell>{m.start_maintenance}</TableCell>
                  <TableCell>{m.end_maintenance}</TableCell>
                  <TableCell>{Weekday(m.daily_maintenance)}</TableCell>
                  <TableCell>{m.announcement}</TableCell>
                  <TableCell>{m.announcement_th}</TableCell>
                  <TableCell>
                    <Switch
                      checked={m.is_active}
                      onChange={(event) => handleChangeActive(event, m.provider_id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEdit(m.provider_id)}
                    >
                      Edit
                    </Button>
                    <Button variant="contained" color="secondary" style={{ margin: '0 5px' }}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </Grid>
      {newMaintenance ? (
        <Grid>
          <DropdownProvider handleCallback={(e) => handleChangeProvider(e)} />
          <div>
            <TextField id="announce" label="Annount" />
          </div>
          <div>
            <TextField id="announce_th" label="Annount TH" />
          </div>
          <div>
            <TextField id="start" label="Start" />
          </div>
          <div>
            <TextField id="end" label="End" />
          </div>
          <Box style={{ margin: '10px' }}>
            <Button variant="contained" color="primary" style={{ margin: '0 5px' }}>
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setNewMaintenance(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      ) : null}
    </ApplicationLayout>
  );
};

export default MaintenanceRoutine;
