import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Button, Popover, Select } from '@material-ui/core';
import { defaultRanges, formatDate } from '../utils/datetime';
import { startOfMonth } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import timezones from 'timezones-list';
import { AllTimeZone } from '../utils/timezone';
import moment from 'moment';
import DateRangeOptionButtonGroup from '../components/DateRangeOptionButtonGroup';

const DateRangePickerBS = ({
  onChange,
  value,
  setMinDate = moment().subtract(3, 'months').toDate(),
  setMaxDate = moment().toDate(),
}) => {
  const [preset, setPreset] = useState({
    name: '(GMT+08:00) Singapore, Woodlands',
    data: {
      label: 'Asia/Singapore (GMT+08:00)',
      name: '(GMT+08:00) Singapore, Woodlands',
      tzCode: 'Asia/Singapore',
      utc: '+08:00',
    },
  });
  const [state, setState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <Select
        native
        value={preset?.data.name}
        onChange={(event) => {
          const name = event.target.value;
          console.log(name);
          if (name) {
            const tz = timezones.find((elem) => elem.name == name);
            console.log('tz: ', tz);
            setPreset({ name: name, data: tz });
          } else {
            setPreset({ name: '', data: '' });
          }
        }}
        inputProps={{ name: 'name', id: 'preset' }}
        style={{ margin: '0 10px' }}
      >
        <option value="">Select Timezone</option>
        {AllTimeZone.map((v) => {
          return (
            <option value={v.value} key={v.name}>
              {v.name}
            </option>
          );
        })}
      </Select>
      <Button aria-describedby={id} variant="contained" color="secondary" onClick={handleClick}>
        {value?.startDate
          ? `${formatDate(value?.startDate)} - ${formatDate(value?.endDate)}`
          : 'Select Time'}
        <DateRangeIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          onChange={(item) => {
            const range = {
              startDate: item.selection.startDate,
              endDate: item.selection.endDate,
              startDateValue: zonedTimeToUtc(
                moment(item.selection.startDate).toDate(),
                preset.data?.tzCode,
              ),
              endDateValue: zonedTimeToUtc(
                moment(item.selection.endDate).toDate(),
                preset.data?.tzCode,
              ),
              timezones: preset,
            };
            console.log('range: ', range);
            onChange(range);
            setState([item.selection]);
          }}
          showSelectionPreview={true}
          months={2}
          ranges={state}
          direction="horizontal"
          showDateDisplay={false}
          dateDisplayFormat={''}
          rangeColors={['#303f9f']}
          maxDate={setMaxDate}
          minDate={setMinDate}
        />
      </Popover>
      <DateRangeOptionButtonGroup onChange={onChange} tz={preset} />
    </>
  );
};

export default DateRangePickerBS;
