import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import {
  getUserPlayerListByPlatformCode,
  listUserPlayerOnlineByPlatformCode,
} from '../../../api/cms/player';

import { LoadingBox } from '../../../components/LoadingBox';
import useSWR from 'swr';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Button, Box, Typography, TextField, Collapse, Grid } from '@material-ui/core';
// import { toast } from 'react-toastify';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useEffect } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { genParamsQueryFromDateRange, useDateRange } from '../../../utils/datetime';
import _ from 'lodash';
import queryString from 'query-string';
import SummaryDateRangeContent from '../../../components/summary/SummaryDateRangeContent';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { format } from 'date-fns';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../components/table/TablePaginationActions';

const PlayerInfoPage = () => {
  let { platformId } = useParams();
  const [expanded, setExpanded] = useState([]);
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [searchObj, setSearch] = React.useState({
    username: '',
  });

  const { search } = useLocation();
  const paramsString = queryString.parse(search);
  const [dateRange, setDateRange] = useDateRange(paramsString);

  const searchParam = React.useMemo(() => {
    return genParamsQueryFromDateRange(dateRange);
  }, [dateRange]);

  var formatedNewDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:00'Z'");

  const [selectedStartDate, handleDateChange] = useState(formatedNewDate);

  const [selectedEndDate, handleEndDateChange] = useState(formatedNewDate);

  const onChangeDateRange = (range) => {
    setDateRange(range);
    console.log('range : ', range);
    console.log('dateRange.startDateValue : ', dateRange.startDateValue);
    console.log('dateRange.endDateValue : ', dateRange.endDateValue);
    var formatedStartDate = format(range.startDateValue, "yyyy-MM-dd'T'HH:mm:00'Z'");
    var formatedEndDate = format(range.endDateValue, "yyyy-MM-dd'T'HH:mm:00'Z'");
    handleDateChange(formatedStartDate);
    handleEndDateChange(formatedEndDate);
    const newSearchUrl = genParamsQueryFromDateRange(range);
    history.replace({
      pathname: '',
      search: `?${newSearchUrl}`,
      state: { isActive: true },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value == -1) {
      event.target.value = parseInt(0, 10);
      console.log('limit 0 : ', event.target.value);
      setRowsPerPage(0);
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(1);
  };

  const searchDebounce = useDebounce(searchObj, 700);

  const { data, error } = useSWR(
    [
      'getUserPlayerListByPlatformCode',
      platformId,
      searchDebounce,
      selectedStartDate,
      selectedEndDate,
      page,
      rowsPerPage,
    ],
    (url, platformId) =>
      getUserPlayerListByPlatformCode(
        platformId,
        searchDebounce?.username,
        selectedStartDate,
        selectedEndDate,
        page,
        rowsPerPage,
      ),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  //List User Player Online
  const { data: listActive, error: error2 } = useSWR(
    ['listUserPlayerOnlineByPlatformCode', platformId, dateRange],
    (url, platformId) => listUserPlayerOnlineByPlatformCode(platformId),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  let isLoading = !error && !data;

  useEffect(() => {
    console.log(data);
    if (data) {
      // const players = data.players;
      setExpanded([...Array(data.players.length)].map((val) => false));
    }
    return () => {};
  }, [data]);

  const handleClick = (index) => {
    setExpanded(
      expanded.map((boolean_value, i) => {
        if (index === i) {
          return !boolean_value;
        } else {
          // all other collapse will be closed
          return false;
        }
      }),
    );
  };
  return (
    <ApplicationLayout>
      <Breadcrumbs
        keepSearchParams
        breadcrumbNameAddOn={{
          [`${location.pathname}`]: `${data?.platform_name || ''} (Player)`,
        }}
      />
      <SummaryDateRangeContent
        onChange={onChangeDateRange}
        defaultValue={dateRange}
        value={dateRange}
      />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div" style={{ float: 'right' }}>
              Current Active User : {listActive?.length || 0}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom component="div">
            User Player List
          </Typography>
          <Grid item xs={2} style={{ float: 'right' }}>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              fullWidth
              id="username"
              label="ID / Username"
              onChange={(e) => setSearch({ ...searchObj, username: e.target.value })}
              value={searchObj?.username}
              defaultValue={searchObj?.username}
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Player ID</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Initial balance</TableCell>
                    <TableCell style={{ color: 'red' }}>Bet balance</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Online</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.players.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow key={uuid()}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleClick(index)}
                          >
                            {expanded[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell>{row.player_id}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>{row.initial_balance}</TableCell>
                        <TableCell style={{ color: 'red' }}>{row.bet_balance}</TableCell>
                        <TableCell>{row.balance}</TableCell>
                        <TableCell>
                          {row.is_active ? (
                            <div style={{ color: 'green' }}>Yes </div>
                          ) : (
                            <div style={{ color: 'red' }}>'No' </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.is_online ? (
                            <div style={{ color: 'green' }}>Yes</div>
                          ) : (
                            <div style={{ color: 'red' }}>No</div>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={expanded[index]} timeout="auto" unmountOnExit={true}>
                            <Box sx={{ margin: 1 }}>
                              <Typography variant="h6" gutterBottom component="div">
                                User Information
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Game ID</TableCell>
                                    <TableCell>Game name</TableCell>
                                    <TableCell align="right">Category</TableCell>
                                    <TableCell align="right">Provider ID</TableCell>
                                    <TableCell align="right">Provider Code</TableCell>
                                    <TableCell align="right">Open date</TableCell>
                                    <TableCell align="right">Close date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow key={uuid()}>
                                    <TableCell component="th" scope="row">
                                      {row.user_information.game_id}
                                    </TableCell>
                                    <TableCell>{row.user_information.game_name}</TableCell>
                                    <TableCell align="right">
                                      {row.user_information.category}
                                    </TableCell>

                                    <TableCell align="right">
                                      {row.user_information.provider_id}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.user_information.provider_code}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.user_information.open_game_date}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.user_information.close_game_date}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={5}
                      count={data?.total}
                      rowsPerPage={rowsPerPage}
                      page={data?.page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </ApplicationLayout>
  );
};

export default PlayerInfoPage;
