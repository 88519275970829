import ApplicationLayout from '../../../../components/layouts/ApplicationLayout';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import UpdateGameForm from '../../../../components/admin/provider/UpdateGameForm';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { getGameById } from 'api/cms/game';
import { toast } from 'react-toastify';

const GameEdit = () => {
  let { gameId } = useParams();
  const { data: game, error: errorGame } = useSWR(
    ['gameById', gameId],
    (url, gameId) => getGameById(gameId),
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
      shouldRetryOnError: false,
    },
  );

  if (errorGame) {
    toast.error('error');
  }

  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <UpdateGameForm game={game} />
    </ApplicationLayout>
  );
};

export default GameEdit;
