import {
  Box,
  Button,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Thead from 'components/table/Thead';
import { useState, useEffect } from 'react';
import { platformBetLimitTableHead } from './thead-data';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { callProviderUpdateBetLimit, changePlatformBetLimit } from 'api/cms/platform';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  'my-5': {
    margin: '30px',
  },
  'mx-5': {
    marginLeft: '1.25rem',
    marginRight: '1.25rem',
  },
}));

const PlatformUserProviderMaxBetSetting = ({ data = {} }) => {
  let { providerId, platformId } = useParams();
  const [bets, setBets] = useState([]);
  const [betDefaults, setBetDefaults] = useState([]);
  const [checkIsUpdate, setCheckIsUpdate] = useState(true);
  const cls = useStyles();
  const swal = withReactContent(Swal);
  useEffect(() => {
    setBets([]);
    console.log(data.max_bet_settings);
    data.provider_max_bet_settings.map((v, i) => {
      setBets((prevState) => [
        ...prevState,
        {
          provider_max_bet_setting: v,
          enabled: data.max_bet_settings?.includes(v.bet_reference) ? true : false,
        },
      ]);
    });
    setBetDefaults(data.max_bet_settings);
    return () => {};
  }, []);

  const handleChange = (evt, data) => {
    const checked = evt.target.checked;
    setBets((prevState) =>
      prevState.map((v) =>
        v.provider_max_bet_setting.bet_reference == data.provider_max_bet_setting.bet_reference
          ? { ...v, enabled: checked }
          : v,
      ),
    );
  };

  const updateBetLimit = () => {
    let arr = [];
    bets.map((v) => {
      v.enabled ? arr.push(v.provider_max_bet_setting.bet_reference) : arr.push();
    });
    console.log(arr);
    swal
      .fire({
        text: `Are you sure update bet limit as ${data.user?.platform_name}`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          changePlatformBetLimit({ platformId, providerId }, { bets: arr })
            .then(() => {
              toast.success(`bet limit changed`);
              swal
                .fire({
                  text: `Would like to change as new setting limit`,
                  icon: 'warning',
                  showCloseButton: true,
                  showCancelButton: true,
                  showConfirmButton: true,
                })
                .then((sResult) => {
                  if (sResult.isConfirmed) {
                    callProviderUpdateBetLimit({ platformId, providerId }).then(() => {
                      toast.info(`pending update player...`);
                    });
                  }
                });
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });

    // changeBetLimit({ provider_id: data.provider_id, bet_ids: arr })
    //   .then(() => {
    //     toast.success('bet limit changed');
    //   })
    //   .catch((error) => {
    //     toast.error(_.get(error, 'response.data.message', 'error'));
    //   });
  };

  const callProvider = () => {
    swal
      .fire({
        text: `Are you sure update bet limit as ${data.user?.platform_name}`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // console.log(platformId, providerId);
          callProviderUpdateBetLimit({ platformId, providerId }).then(() => {});

          // updateProviderById(item.provider_id, payload)
          //   .then(() => {
          //     let arr = [...data];
          //     let thisObj = arr.find((obj) => obj.provider_id == item.provider_id);
          //     thisObj.is_maintenance = is_active;
          //     setProvider(arr);
          //     toast.success('updated');
          //   })
          //   .catch((error) => {
          //     toast.error(_.get(error, 'response.data.message', 'error'));
          //   });
        }
      });
    console.log('call provider');
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        Minimum/ Maximum Bet Limit Settings
      </Typography>
      {/* <Typography variant="h6" gutterBottom component="div">
        Status: <span style={{ color: 'red' }}>Not Updated</span>
        <Button
          color="default"
          variant="contained"
          className={cls['mx-5']}
          onClick={() => callProvider()}
        >
          Call Provider Update
        </Button>
      </Typography> */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Table size="small">
          <Thead heads={platformBetLimitTableHead} align="center" />
          <TableBody>
            {bets.length > 0 ? (
              bets.map((v, i) => (
                <TableRow key={v.id}>
                  <TableCell align="center">
                    <Checkbox
                      color="primary"
                      checked={v.enabled}
                      onChange={(evt) => handleChange(evt, v)}
                    />
                  </TableCell>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="center">{v.provider_max_bet_setting?.label}</TableCell>
                  <TableCell align="center">{v.provider_max_bet_setting?.value}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Box className={cls['my-5']}>
        <Button
          onClick={() => updateBetLimit()}
          color="primary"
          variant="contained"
          disabled={!checkIsUpdate}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default PlatformUserProviderMaxBetSetting;
