import {
  Box,
  Button,
  Checkbox,
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { changeBetLimit } from 'api/cms/provider';
import Thead from 'components/table/Thead';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { providerBetLimitTableHead } from './thead-data';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  'my-5': {
    margin: '30px',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ProviderMaxBetSetting = ({ data = {} }) => {
  const [betDefaults, setBetDefaults] = useState([]);
  const [editBet, setEditBet] = useState(false);
  const cls = useStyles();
  useEffect(() => {
    setBetDefaults([]);
    data.provider_max_bet_settings.map((v, i) => {
      v[`set_bet_reference`] = v.bet_reference;
      v[`set_label`] = v.label;
      v[`set_value`] = v.value;
      setBetDefaults((prevState) => [
        ...prevState,
        {
          provider_max_bet_setting: v,
          enabled: data.default_max_bet_settings.includes(v.bet_reference) ? true : false,
          editable: false,
        },
      ]);
    });
    return () => {};
  }, []);

  const handleChange = (evt, data) => {
    const checked = evt.target.checked;
    setBetDefaults((prevState) =>
      prevState.map((v) =>
        v.provider_max_bet_setting.bet_reference == data.provider_max_bet_setting.bet_reference
          ? { ...v, enabled: checked }
          : v,
      ),
    );
  };

  const updateBetLimit = () => {
    let arr = [];
    betDefaults.map((v) => {
      v.enabled ? arr.push(v.provider_max_bet_setting.bet_reference) : arr.push();
    });
    changeBetLimit({ provider_id: data.provider_id, bet_ids: arr })
      .then(() => {
        toast.success('bet limit changed');
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  const editBetLimit = (data) => {
    console.log(data);
    setBetDefaults((prevState) =>
      prevState.map((v) =>
        v.provider_max_bet_setting.bet_reference == data.provider_max_bet_setting.bet_reference
          ? { ...v, editable: true }
          : v,
      ),
    );
  };

  const changeBetLimit = (data) => {
    console.log('changeBetLimit: ', data);
    setBetDefaults((prevState) =>
      prevState.map((v) =>
        v.provider_max_bet_setting.bet_reference == data.provider_max_bet_setting.bet_reference
          ? { ...v, editable: false }
          : v,
      ),
    );
  };

  const valueChange = () => {};

  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        <div className="">Minimum/ Maximum Bet Limit Settings</div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => setEditBet(!editBet)}
        >
          Edit
        </Button>
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Table size="small">
          <Thead heads={providerBetLimitTableHead} align="center" />
          <TableBody>
            {betDefaults.map((v, i) => (
              <TableRow key={v.id}>
                <TableCell align="center">
                  <Checkbox
                    color="primary"
                    checked={v.enabled}
                    onChange={(evt) => handleChange(evt, v)}
                  />
                </TableCell>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">
                  {v.editable ? (
                    <Input
                      defaultValue={v.provider_max_bet_setting?.label}
                      onChange={(evt) => valueChange(evt)}
                    />
                  ) : (
                    `${v.provider_max_bet_setting?.label}`
                  )}
                </TableCell>
                <TableCell align="center">
                  {v.editable ? (
                    <Input defaultValue={v.provider_max_bet_setting?.value} />
                  ) : (
                    `${v.provider_max_bet_setting?.value}`
                  )}
                </TableCell>
                <TableCell align="center">
                  {v.editable ? (
                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => changeBetLimit(v)}
                        className={cls.button}
                      >
                        <SaveIcon />
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => changeBetLimit(v)}
                        className={cls.button}
                      >
                        <CancelIcon />
                      </Button>
                    </div>
                  ) : (
                    <Button color="secondary" variant="contained" onClick={() => editBetLimit(v)}>
                      <EditIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box className={cls['my-5']}>
        <Button onClick={() => updateBetLimit()} color="primary" variant="contained">
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default ProviderMaxBetSetting;
