import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginPage from './pages/Login';
import TopicsPage from './pages/Topics';
import Profile from './pages/Profile';
import Config from './pages/Config';
import ErrorPage from './pages/ErrorPage';
import PlatformList from './pages/admin/platform_list';
import PlatformInfoPage from './pages/admin/platform_list/PlatformInfoPage';
import PlayerInfoPage from './pages/admin/platform_list/PlayerInfoPage';
import DashboardPage from './pages/DashboardPage';
import PlayerListPage from 'pages/admin/player_list';

import ProviderInfoWithGamePage from './pages/admin/platform_list/ProviderInfoWithGamePage';
import ProviderList from './pages/admin/provider_list';
import ProviderInfoPage from './pages/admin/provider_list/ProviderInfoPage';
import GameInfoPage from './pages/admin/game_verified/GameInfoPage';
import GameNew from './pages/admin/provider_list/game/GameNew';
import MaintenanceNew from './pages/admin/provider_list/Maintenance/MaintenanceNew';
import GameEdit from './pages/admin/provider_list/game/GameEdit';
import UserList from './pages/admin/UserList';
import UserSummary from 'pages/summary/users';

import SummaryByPlatform from './pages/summary/platform_list';
import SummaryByProvider from './pages/summary/provider_list';
import PlatformSummaryByProvider from './pages/summary/provider_list/ProviderDetail';
import ProviderSummaryByPlatform from './pages/summary/platform_list/PlatformDetail';
import TransactionByPlatform from './pages/summary/platform_list/Transaction';
import TransactionByProvider from './pages/summary/provider_list/Transaction';
import Summary from './pages/summary';
import Transaction from './pages/summary/Transaction';
import PlatformGroupUser from './pages/summary/platform_list/PlatformGroupUser';

import GameVerified from './pages/admin/game_verified';
import Storage from './pages/admin/Storage';
import RepairWinLoss from './pages/admin/RepairWinLoss';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingBox } from './components/LoadingBox';
import { useUser } from './store/user';
import { toast } from 'react-toastify';
import MaintenanceRoutine from 'pages/admin/MaintenanceRoutine';
import UserHistory from 'pages/summary/users/user_history';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  async function (config) {
    let localStorage = window.localStorage.getItem('kk-token');
    localStorage = JSON.parse(localStorage);
    if (localStorage?.token) {
      config.headers['Authorization'] = `Bearer ${localStorage.token.access_token}`;
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

const App = () => {
  const [loading, setLoading] = useState(false);
  const [{ isLogin, user, isAdmin }, setUser] = useUser();
  axios.interceptors.response.use(
    async function (config) {
      return config;
    },
    function (error) {
      if (error.response.status === 401) {
        toast.error(error?.message || 'error');
        setUser(null);
      }
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // const token = useRecoilValue(tokenRecoil);

  // useEffect(async () => {
  //   setLoading(true);
  //   if (token) {
  //     await api.setAuthToken(token.access_token);
  //     console.log('set', api.api);
  //     setLoading(false);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [token]);

  if (loading) return <LoadingBox height="100vh" />;

  return (
    <BrowserRouter>
      <Switch>
        {/* <PrivateRoute path="/" exact component={isAdmin ? PlatformList : Profile}></PrivateRoute> */}
        <PrivateRoute path="/" exact component={DashboardPage}></PrivateRoute>
        <PrivateRoute path="/profile" exact component={Profile}></PrivateRoute>
        <PrivateRoute path="/config" exact roles={[1]} component={Config}></PrivateRoute>
        <PrivateRoute
          path="/admin/game_verified"
          exact
          roles={[1000]}
          component={GameVerified}
        ></PrivateRoute>
        <PrivateRoute path="/admin/storage" exact roles={[1000]} component={Storage}></PrivateRoute>
        <PrivateRoute
          path="/admin/game_verified/:gameId"
          exact
          roles={[1000]}
          component={GameInfoPage}
        ></PrivateRoute>
        <PrivateRoute
          path="/platform_list"
          exact
          roles={[1000]}
          component={PlatformList}
        ></PrivateRoute>
        <PrivateRoute
          path="/player_list"
          exact
          roles={[3, 1000]}
          component={PlayerListPage}
        ></PrivateRoute>
        <PrivateRoute
          path="/platform_list/:platformId"
          roles={[1000]}
          exact
          component={PlatformInfoPage}
        ></PrivateRoute>
        <PrivateRoute
          path="/player_list/:platformId"
          roles={[1000]}
          exact
          component={PlayerInfoPage}
        ></PrivateRoute>
        <PrivateRoute
          path="/platform_list/:platformId/:providerId"
          roles={[1000]}
          exact
          component={ProviderInfoWithGamePage}
        ></PrivateRoute>
        {/* provider */}
        <PrivateRoute
          path="/provider_list"
          exact
          roles={[1000]}
          component={ProviderList}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/provider_list/:providerId"
          roles={[1000]}
          component={ProviderInfoPage}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/provider_list/:providerId/game/new"
          roles={[1000]}
          component={GameNew}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/provider_list/:providerId/maintenance/new"
          roles={[1000]}
          component={MaintenanceNew}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/provider_list/:providerId/game/:gameId/edit"
          roles={[1000]}
          component={GameEdit}
        ></PrivateRoute>
        {/* summary */}
        <PrivateRoute path="/summary" exact roles={[1]} component={Summary}></PrivateRoute>
        <PrivateRoute
          path="/summary/provider"
          roles={[1000]}
          exact
          component={SummaryByProvider}
        ></PrivateRoute>
        <PrivateRoute
          path="/summary/provider/:providerId"
          // roles={[1000]}
          exact
          component={isAdmin ? PlatformSummaryByProvider : Transaction}
        ></PrivateRoute>
        <PrivateRoute
          path="/summary/users"
          roles={[1000]}
          exact
          component={UserSummary}
        ></PrivateRoute>
        <PrivateRoute
          path="/summary/users/history"
          roles={[1000]}
          exact
          component={UserHistory}
        ></PrivateRoute>
        <PrivateRoute path="/user_list" exact roles={[1000]} component={UserList}></PrivateRoute>
        <PrivateRoute
          path="/summary/provider/:providerId/platform/:platformId"
          roles={[1000]}
          exact
          component={TransactionByProvider}
        ></PrivateRoute>
        <PrivateRoute
          path="/summary/platform"
          roles={[1000]}
          exact
          component={SummaryByPlatform}
        ></PrivateRoute>
        <PrivateRoute
          path="/summary/platform/:platformId"
          roles={[1000]}
          exact
          component={ProviderSummaryByPlatform}
        ></PrivateRoute>
        <PrivateRoute
          path="/summary/platform/:platformId/provider/:providerId/user"
          roles={[1000]}
          exact
          component={PlatformGroupUser}
        ></PrivateRoute>
        <PrivateRoute
          path="/summary/platform/:platformId/provider/:providerId/transaction/:username"
          roles={[1000]}
          exact
          component={TransactionByPlatform}
        ></PrivateRoute>
        <PrivateRoute
          roles={[1000]}
          path="/admin/repair_win_loss"
          component={RepairWinLoss}
        ></PrivateRoute>
        <PrivateRoute
          roles={[1000]}
          path="/admin/routine_maintenance"
          component={MaintenanceRoutine}
        ></PrivateRoute>
        <PublicRoute path="/topics" component={TopicsPage}></PublicRoute>
        <PublicRoute path="/login" restricted component={LoginPage}></PublicRoute>
        <Route path="*" component={ErrorPage}></Route>
      </Switch>
      <ToastContainer draggable={false} autoClose={2000} hideProgressBar={false} closeOnClick />
    </BrowserRouter>
  );
};

export default App;
