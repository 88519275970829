import React, { useMemo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import List from '@material-ui/core/List';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import AppMenuItemComponent from './AppMenuItemComponent';
import { useLocation } from 'react-router-dom';

const AppMenuItem = (props) => {
  const { name, link, Icon, items = [] } = props;
  const isExpandable = items && items.length > 0;
  const classes = useStyles({ isExpandable });

  let location = useLocation();

  // const shouldOpen = useMemo(() => {
  //   const check = items.map((obj) => {
  //     return obj.items && obj.items.length ? obj.items.map((k) => k.link) : obj.link;
  //   });
  //   return check.reduce((o, m) => m.concat(o), []).includes(location.pathname);
  // });

  const [open, setOpen] = React.useState(true);

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
      setOpen={setOpen}
      open={open}
    >
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={name}
        inset={!Icon}
        className={classnames(isExpandable ? classes.menuItemText : '')}
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null;

  const MenuAppVersion = (
    <>
      <Divider />
      <List> VERSION </List>
    </>
  );

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      background: (props) => (props.isExpandable ? 'rgba(0, 0, 0, 0.02)' : null),
      // '&:hover': {
      //   background: (props) => (props.isExpandable ? theme.palette.text.primary : null),
      // },
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
        // '& .MuiListItemIcon-root': {
        //   color: '#fff',
        // },
      },
    },
    // menuItemIcon: {
    //   color: '#97c05c',
    // },
    menuItemText: {
      '& > span': {
        fontWeight: `700 !important`,
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export default AppMenuItem;
