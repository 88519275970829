import { useState, useEffect } from 'react';
import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import { updateProviderById, getProviderList, allMaintenance } from '../../../api/cms/provider';
import { LoadingBox } from '../../../components/LoadingBox';
import useSWR, { mutate } from 'swr';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Paper,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
  Box,
  ButtonGroup,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { toast } from 'react-toastify';
import { Link, useRouteMatch } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGlow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  gridLayout: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ProviderList = () => {
  const { data, error } = useSWR('provider', () => getProviderList());
  const [provider, setProvider] = useState([]);
  const classes = useStyles();
  const swal = withReactContent(Swal);

  let match = useRouteMatch();
  if (error) toast.error('error');

  useEffect(() => {
    // data.map((row, index) => {
    //   setProviderActive([]);
    // });
    if (data) {
      setProvider(data);
    }

    return () => {};
  }, [data]);

  const onActiveChange = (is_active, item) => {
    var payload = {
      is_maintenance: is_active,
    };
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to ${is_active ? 'enable' : 'disable'} ${
          item.provider_name
        } maintenance`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateProviderById(item.provider_id, payload)
            .then(() => {
              let arr = [...data];
              let thisObj = arr.find((obj) => obj.provider_id == item.provider_id);
              thisObj.is_maintenance = is_active;
              setProvider(arr);
              toast.success('updated');
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
  };

  const onPlatformEnableChange = (is_active, item) => {
    var payload = {
      is_platform_enable: is_active,
    };
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to ${is_active ? 'enable' : 'disable'} ${
          item.provider_name
        } platform list?`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateProviderById(item.provider_id, payload)
            .then(() => {
              let arr = [...data];
              let thisObj = arr.find((obj) => obj.provider_id == item.provider_id);
              thisObj.is_platform_enable = is_active;
              setProvider(arr);
              toast.success('updated');
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
  };

  const callAllMaintenance = (is_active) => {
    const label = is_active ? 'enabled' : 'disabled';
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to ${is_active ? 'enable' : 'disable'} maintenance`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          allMaintenance(is_active)
            .then(() => {
              toast.success(`maintenance is: ${label}`);
              mutate(`provider`);
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
  };

  return (
    <ApplicationLayout>
      {!provider ? (
        <LoadingBox />
      ) : (
        <>
          <Breadcrumbs />
          <Typography variant="h6" gutterBottom component="div">
            Provider List
          </Typography>
          <Grid className={classes.gridLayout}>
            <Box bgcolor={'white'} className={classes.paper}>
              <Typography gutterBottom component="div">
                Maintenance Providers
              </Typography>
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
              >
                <Button onClick={() => callAllMaintenance(true)}>Enabled</Button>
                <Button onClick={() => callAllMaintenance(false)}>Disabled</Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Logo</TableCell>
                  <TableCell>Provider Name</TableCell>
                  <TableCell>Provider Code</TableCell>
                  <TableCell>Contact Name</TableCell>
                  {/*<TableCell>Email</TableCell>*/}
                  {/*<TableCell>Phone Number</TableCell>*/}
                  <TableCell>Maintenance</TableCell>
                  <TableCell>Platform Enable</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {provider.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <img src={row.provider_logo} height={30} />
                    </TableCell>
                    <TableCell>{row.provider_name}</TableCell>
                    <TableCell>{row.provider_code}</TableCell>
                    <TableCell>{row.contact_person}</TableCell>
                    {/*<TableCell>*/}
                    {/*  <a href={`mailto:${row.email}`}>{row.email}</a>*/}
                    {/*</TableCell>*/}
                    {/*<TableCell>*/}
                    {/*  <a href={`tel:${row.phone_number}`}>{row.phone_number}</a>*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.is_maintenance}
                            onChange={(event) => {
                              // setPlatformId(row.user_id);
                              onActiveChange(event.target.checked, row);
                            }}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.is_platform_enable}
                            onChange={(event) => {
                              // setPlatformId(row.user_id);
                              onPlatformEnableChange(event.target.checked, row);
                            }}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Link to={`${match.url}/${row.provider_id}`}>
                        <Button variant="contained" color="secondary">
                          <ExitToAppIcon />
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </ApplicationLayout>
  );
};

export default ProviderList;
