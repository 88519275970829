import axios from 'axios';
import _ from 'lodash';

export const getProviderList = () => {
  return axios.get('/cms/provider/list').then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data.providers', []);
    }
  });
};

export const getProviderById = (id) => {
  return axios.get(`/cms/provider/${id}`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', null);
    }
  });
};

export const updateProviderById = (id, payload) => {
  return axios.put(`/cms/provider/${id}`, payload).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data', null);
    }
  });
};

export const getCatagoryProvider = (providerId) => {
  return axios.get(`/cms/provider/${providerId}`).then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data.category', []);
    }
  });
};

export const getGameByProviderId = ({ providerId, limit, page, search_game, category }) => {
  return axios
    .get(`/cms/provider/${providerId}/game/list`, {
      params: { limit, page, search_game, category },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', []);
      }
    });
};

export const getMaintenanceList = () => {
  return axios.get('/cms/maintenance/list').then((res) => {
    if (res.data.code === 0) {
      return _.get(res, 'data.data.maintenances', []);
    }
  });
};

export const createMaintenance = (payload) => {
  return axios.post(`/cms/maintenance`, payload).then((res) => res.data);
};

export const updateMaintenanceByMaintenanceID = (id, payload) => {
  return axios.put(`/cms/maintenance/${id}`, payload).then((res) => res.data);
};

export const allMaintenance = (is_active) => {
  return axios
    .post(`/cms/provider/maintenance/list/enabled`, { enabled: is_active })
    .then((res) => res.data);
};

export const changeBetLimit = (payload) => {
  return axios.post(`/cms/provider/default_bet_limit/change`, payload).then((res) => res.data);
};

export const updateProviderConfig = (providerId, payload) => {
  return axios.put(`/cms/provider/${providerId}/config`, payload);
};
