import Cell from './Cell';
import { formatNumber, isNegativeNumber } from '../../utils/number';
import classnames from 'classnames';

const OurGroup = ({ data, isAdvance, disabledBg = false, rowSpan }) => {
  return (
    <>
      {isAdvance && (
        <Cell
          align="center"
          className={classnames(
            !disabledBg && 'bg-table-secondary',
            // isNegativeNumber(data?.diff_royalty_fee_percent || 0) ? 'text-red' : 'text-blue',
          )}
        >
          {formatNumber(data?.diff_royalty_fee_percent || 0)}%
        </Cell>
      )}
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(data?.diff_royalty_fee || 0) ? 'text-red' : 'text-blue',
        )}
        text={formatNumber(data?.diff_royalty_fee || 0)}
        rowSpan={rowSpan}
      />
    </>
  );
};

export default OurGroup;
