import {
  Button,
  Grid,
  Link,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Breadcrumbs from 'components/Breadcrumbs';
import DropdownProvider from 'components/DropdownProvider';
import ApplicationLayout from 'components/layouts/ApplicationLayout';
import SummaryDateRangeContent from 'components/summary/SummaryDateRangeContent';
import { genParamsQueryFromDateRange, useDateRange } from '../../../utils/datetime';
import queryString from 'query-string';
import { useState, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import Thead from 'components/table/Thead';
import { getWinLossGroupUserByPlatform } from '../../../api/cms/summary';
import TablePaginationActions from '../../../components/table/TablePaginationActions';
import useSWR from 'swr';
import { toast } from 'react-toastify';

const useStyle = makeStyles({
  container: {
    maxHeight: 500,
  },
  marginX2: {
    margin: '15px 0',
  },
  danger: {
    color: 'red',
  },
  primary: {
    color: 'blue',
  },
});

const UserSummary = () => {
  const { search } = useLocation();
  const paramsString = queryString.parse(search);
  const classes = useStyle();
  const match = useRouteMatch();

  const [provider, setProvider] = useState({
    provider_name: '',
    provider_code: '',
    provider_id: '',
  });
  const [dateRange, setDateRange] = useDateRange(paramsString);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [transaction, setTransaction] = useState({ txns: [], total: 0 });
  const searchParam = useMemo(() => {
    return genParamsQueryFromDateRange(dateRange);
  }, [dateRange]);

  const handleProviderChange = (evt) => {
    console.log(evt);
    setProvider(evt);
  };

  const onChangeDateRange = (range) => {
    console.log('onChangeDateRange: ', range);
    setDateRange(range);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getWinLossGroupUserByPlatform({
      page: page + 1,
      limit: rowsPerPage,
      provider_code: provider.provider_code,
      from_time: dateRange.startDateValue,
      to_time: dateRange.endDateValue,
    }).then((res) => {
      setTransaction({ txns: res.transactions, total: res.meta.total });
      console.log(transaction);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getWinLossGroupUserByPlatform({
      page: page + 1,
      limit: rowsPerPage,
      provider_code: provider.provider_code,
      from_time: dateRange.startDateValue,
      to_time: dateRange.endDateValue,
    }).then((res) => {
      setTransaction({ txns: res.transactions, total: res.meta.total });
      console.log(transaction);
    });
  };

  // const { data, error, mutate } = useSWR(
  //   ['getWinLossGroupUserByPlatform', page, rowsPerPage, dateRange],
  //   (provider, page, limit) =>
  //     getWinLossGroupUserByPlatform({
  //       page: page,
  //       limit,
  //       provider_code: provider.provider_code,
  //       from_time: dateRange.startDateValue,
  //       to_time: dateRange.endDateValue,
  //     }),
  //   {
  //     revalidateOnFocus: false,
  //     refreshInterval: 0,
  //     shouldRetryOnError: false,
  //   },
  // );

  // console.log('data transactions: ', data);
  // if (error) toast.error('error: ', error);

  const onSearchUser = () => {
    if (provider.provider_code == '') {
      toast.error('please select provider');
      return;
    }

    getWinLossGroupUserByPlatform({
      page: page + 1,
      limit: rowsPerPage,
      provider_code: provider.provider_code,
      from_time: dateRange.startDateValue,
      to_time: dateRange.endDateValue,
    }).then((res) => {
      setTransaction({ txns: res.transactions, total: res.meta.total });
      console.log(transaction);
    });
    // getWinLossGroupUserByPlatform({
    //   provider_code: provider.provider_code,
    //   from_time: dateRange.startDateValue,
    //   to_time: dateRange.endDateValue,
    // }).then((res) => {
    // setData({ transaction: res, total: res.length });
    // console.log(res);
    // });
  };

  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <SummaryDateRangeContent
        onChange={onChangeDateRange}
        defaultValue={dateRange}
        value={dateRange}
      />
      <Grid container className={classes.marginX2}>
        <Grid item sm={12} md={4}>
          <DropdownProvider handleCallback={handleProviderChange} />
        </Grid>
        <Grid item sm={12} md={4}>
          <Button onClick={() => onSearchUser()} variant="contained" color="primary">
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <TableContainer className={classes.container}>
          <Table size="small" aria-aria-label="summary table">
            <Thead
              heads={[
                { name: 'ชื่อผู้ใช้', colspan: 1 },
                { name: 'แพลตฟอร์ม', colspan: 1 },
                { name: 'ยอดแทง', colspan: 1 },
                { name: 'ยอดแทงจริง', colspan: 1 },
                { name: 'ยอดแพ้/ชนะ', colspan: 1 },
                { name: 'ยอดรวมสุทธิ์', colspan: 1 },
              ]}
            />
            {transaction.txns.map((value, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    href={`${match.url}/history?provider_code=${provider.provider_code}&platform_code=${value.platform_code}&${searchParam}`}
                    color="inherit"
                  >
                    {value.username}
                  </Link>
                </TableCell>
                <TableCell>{value.platform_code}</TableCell>
                <TableCell>{value.bet_balance}</TableCell>
                <TableCell>{value.valid_bet_balance}</TableCell>
                <TableCell>{value.win_loss_balance}</TableCell>
                <TableCell
                  className={value.profit_loss_balance * -1 > 0 ? classes.danger : classes.primary}
                >
                  {value.profit_loss_balance * -1}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[100, 250, 500]}
                colSpan={6}
                count={transaction.total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </Table>
        </TableContainer>
      </Grid>
    </ApplicationLayout>
  );
};

export default UserSummary;
