import axios from 'axios';
import _ from 'lodash';

export const getWinLostOverall = ({ to_time, from_time }) => {
  return axios.get('/cms/report', { params: { to_time, from_time } }).then((res) => {
    if (res.data.code === 0) {
      return [_.get(res, 'data.data', {})] || [];
    }
  });
};

export const getWinLossOverall = ({ to_time, from_time }) => {
  return axios
    .get('/cms/report_transaction/summary/log', {
      params: { to_time, from_time },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return [_.get(res, 'data.data', {})] || [];
      }
    });
};

export const getWinLossOverallPlatform = ({ to_time, from_time, platform_code, provider_code }) => {
  return axios
    .get('/cms/report_transaction/summary/log', {
      params: { to_time, from_time, platform_code, provider_code },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return [_.get(res, 'data.data', {})] || [];
      }
    });
};

export const getWinLossOverallProvider = ({ to_time, from_time, provider_code }) => {
  return axios
    .get('/cms/report_transaction/summary/log', {
      params: { to_time, from_time, provider_code },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', {});
      }
    });
};

export const getWinLostOverallOfProvider = ({ providerId, to_time, from_time }) => {
  return axios
    .get(`/cms/report/provider/${providerId}`, { params: { to_time, from_time } })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', {});
      }
    });
};

export const getWinLostOverallOfPlatform = ({ platformId, to_time, from_time }) => {
  return axios
    .get(`/cms/report/platform/${platformId}`, { params: { to_time, from_time } })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', {});
      }
    });
};

export const getWinLostByProvider = ({ page, limit, platform_code, to_time, from_time }) => {
  return axios
    .get(`/cms/report/providers/filter`, {
      params: { page, limit, platform_code, to_time, from_time },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', []);
      }
    });
};

export const getWinLossByProvider = ({ page, limit, platform_code, to_time, from_time }) => {
  return axios
    .get(`/cms/report_transaction/providers/filter`, {
      params: { page, limit, platform_code, to_time, from_time },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', []);
      }
    });
};

export const getWinLostByPlatform = ({ page, limit, provider_code, to_time, from_time }) => {
  // console.log('{ page, limit }', page, limit);
  return axios
    .get(`/cms/report/platforms/filter`, {
      params: { page, limit, provider_code, to_time, from_time },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', []);
      }
    });
};

export const getWinLossByPlatform = ({ page, limit, provider_code, to_time, from_time }) => {
  // console.log('getWinLossByPlatform filter: ', from_time, to_time);
  return axios
    .get(`/cms/report_transaction/platforms/filter`, {
      params: { page, limit, provider_code, to_time, from_time },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', []);
      }
    });
};

export const getTransactionOverAll = ({
  page,
  limit,
  provider_code,
  platform_code,
  to_time,
  from_time,
}) => {
  return axios
    .get(`/cms/report/summary/log`, {
      params: { page, limit, provider_code, platform_code, to_time, from_time },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', {});
      }
    });
};

export const getTransactionList = ({
  page,
  limit,
  provider_code,
  platform_code,
  to_time,
  from_time,
  username,
}) => {
  return axios
    .get(`/cms/report/list`, {
      params: { page, limit, provider_code, platform_code, to_time, from_time, username },
    })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', []);
      }
    });
};

export const getTransactionById = ({ page, limit, transaction_id }) => {
  return axios
    .get(`/cms/report/log/${transaction_id}/detail`, { params: { page, limit } })
    .then((res) => {
      if (res.data.code === 0) {
        return _.get(res, 'data.data', []);
      }
    });
};

// export const getWinLossGroupUserByPlatform = ({
//   page,
//   limit,
//   provider_code,
//   platform_code,
//   from_time,
//   to_time,
// }) => {
//   return axios
//     .get(`/cms/report/summary/user`, {
//       params: { provider_code, platform_code, from_time, to_time },
//     })
//     .then((res) => {
//       if (res.data.code === 0) {
//         return _.get(res, 'data.data', []);
//       }
//     });
// };

export const getWinLossGroupUserByPlatform = ({
  page,
  limit,
  provider_code,
  platform_code,
  from_time,
  to_time,
}) => {
  return axios
    .get(`/cms/report_transaction/user/filter`, {
      params: { page, limit, provider_code, platform_code, from_time, to_time },
    })
    .then((res) => {
      console.log('res: ', res);
      if (res.data.code === 0) {
        return _.get(res, 'data.data', {});
      }
    });
};
