import axios from 'axios';
import _ from 'lodash';

export const getRegularMaintenance = () => {
  return axios.get(`/cms/maintenance/regular_maintenance/list`).then((res) => {
    if (res.data.code === 0) {
      // console.log(res.data.data);
      return _.get(res, 'data.data', []);
    }
  });
};
