export const formatNumber = (numb) => {
  if (numb == null || numb == undefined || isNaN(numb)) return 0;
  var str = numb.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
};

export const isNegativeNumber = (numb) => {
  numb = parseFloat(numb);
  if (numb < 0) return true;
  return false;
};
