import CustomerGroup from './CustomerGroup';
import PlatformGroup from './PlatformGroup';
import ProviderGroup from './ProviderGroup';
import OurGroup from './OurGroup';
import classnames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';

const RoyaltyFeeGroup = ({ data, disabledBg = false, rowSpan }) => {
  return (
    <>
      <CustomerGroup data={data} disabledBg={disabledBg} />
      <PlatformGroup data={data} disabledBg={disabledBg} rowSpan={rowSpan} />
      <OurGroup data={data} disabledBg={disabledBg} rowSpan={rowSpan} />
      <ProviderGroup data={data} disabledBg={disabledBg} rowSpan={rowSpan} />
    </>
  );
};

export const RoyaltyFeeGroupPlatform = ({ data, disabledBg = false, rowSpan }) => {
  return (
    <>
      <CustomerGroup data={data} disabledBg={disabledBg} />
      <PlatformGroup data={data} disabledBg={disabledBg} />
    </>
  );
};

export default RoyaltyFeeGroup;
