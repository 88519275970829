import {
  Box,
  FormControlLabel,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TextField,
  Grid,
  Switch,
} from '@material-ui/core';
import Swal from 'sweetalert2';

const ProviderAffiliateSetting = ({ data = [] }) => {
  console.log(data);
  const onChangeAffiliate = (enabled, data) => {
    // Swal.fire({
    //   title: 'Are you sure to change affiliate',
    // })
    // console.log(enabled, data);
  };

  // const onEditAffiliate = (data, idx) => {
  //   providerAffilates.map((v, i) => {
  //   });
  // };

  // const onSaveAffiliate = (data, idx) => {
  //   console.log(data, idx);
  //   providerAffilates.map((v, i) => {
  //   });
  // };

  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        Affiliate Settings
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid item xs={12} style={{ margin: '15px auto' }}>
          <TableContainer component={Paper}>
            <Table aria-label="game table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell>Sort Order</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0
                  ? data
                      .sort((a, b) => a.order - b.order)
                      .map((v, i) => (
                        <TableRow key={`tr-${i}`}>
                          <TableCell>{v.category.toUpperCase()}</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={v.enabled}
                                  onChange={(event) => {
                                    onChangeAffiliate(event.target.checked, v);
                                  }}
                                />
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {v.is_edit_mode ? (
                              <FormControlLabel control={<TextField defaultValue={v.order} />} />
                            ) : (
                              v.order
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProviderAffiliateSetting;
