import Cell from './Cell';
import { formatNumber, isNegativeNumber } from '../../utils/number';
import classnames from 'classnames';

const PlatformGroup = ({ data, isAdvance, disabledBg = false, rowSpan }) => {
  return (
    <>
      {isAdvance && (
        <Cell
          align="center"
          className={classnames(
            !disabledBg && 'bg-table-primary',
            // isNegativeNumber(data?.royalty_fee_percent || 0) ? 'text-red' : 'text-blue',
          )}
        >
          {formatNumber(data?.royalty_fee_percent || 0)}%
        </Cell>
      )}
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-primary',
          isNegativeNumber(data.royalty_fee) ? 'text-red' : 'text-blue',
        )}
        text={formatNumber(data.royalty_fee)}
        rowSpan={rowSpan}
      />
    </>
  );
};

export default PlatformGroup;
