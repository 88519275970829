import { atom, useRecoilState } from 'recoil';

export const layout = atom({
  key: 'layout',
  default: {
    open: true,
  },
});

export const useLayout = () => useRecoilState(layout);
