import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import Breadcrumbs from '../../../components/Breadcrumbs';
import GameVerifiedListTable from '../../../components/admin/game_verified/GameVerifiedListTable';
import { useRouteMatch } from 'react-router';
import useSWR from 'swr';
import { getGameVerified } from 'api/cms/game';
import { toast } from 'react-toastify';
import { LoadingBox } from 'components/LoadingBox';
import { Grid } from '@material-ui/core';
import { Games } from '@material-ui/icons';

const GameVerified = () => {
  const { data, error, mutate } = useSWR('game_verified', () => getGameVerified(), {
    revalidateOnFocus: false,
  });
  let match = useRouteMatch();

  if (error) toast.error('error');

  const cb = (callback) => {
    if (callback) {
      mutate('game_verified');
    }
  };

  if (!data) {
    return <LoadingBox />;
  } else {
    return (
      <ApplicationLayout>
        <Breadcrumbs />
        <Grid item xs={12}>
          <GameVerifiedListTable games={data?.games} cb={cb} />
        </Grid>
      </ApplicationLayout>
    );
  }
};

export default GameVerified;
