import ApplicationLayout from '../../components/layouts/ApplicationLayout';
import { useFetcher } from '../../hooks/useFetcher';
import { getUserList } from '../../api/cms/user';
import { LoadingBox } from '../../components/LoadingBox';
import useSWR from 'swr';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { updatePlatformById } from '../../api/cms/platform';

const UserList = () => {
  const [platformActive, setPlatformActive] = useState(false);
  const [platformId, setPlatformId] = useState();

  const { data, error } = useSWR(['getUserList', platformActive, platformId], () => getUserList());
  if (error) toast.error('error');

  const onActiveChange = (is_active, id) => {
    console.log('isactive : ', is_active);
    console.log('id : ', id);
    console.log('platformId : ', platformId);

    updatePlatformById(id, { is_active })
      .then(() => {
        setPlatformId(id);
        setPlatformActive(is_active);
        toast.success('updated');
      })
      .catch((error) => {
        toast.error('can not updated');
      });
  };
  return (
    <ApplicationLayout>
      {!data ? (
        <LoadingBox />
      ) : (
        <>
          <Typography variant="h6" gutterBottom component="div">
            User List
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="user table">
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell>{row.phone_number}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.is_active}
                            onChange={(event) => {
                              // setPlatformId(row.user_id);
                              onActiveChange(event.target.checked, row.user_id);
                            }}
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </ApplicationLayout>
  );
};

export default UserList;
