import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { classnames } from 'classnames';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGlow: 1,
  },
  img: {
    width: '100%',
    height: 'auto',
    maxWidth: '200px',
  },
  cursor: {
    cursor: 'pointer',
  },
}));

import DialogImageBox from '../../../components/ImageBox';

const ProviderImageCategory = ({ data = [] }) => {
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const classes = useStyles();

  const onChangeValueHandler = (val) => {
    setFile(val);
  };

  const onImageChangeHandler = (val) => {
    console.log('provider_logo val: ', val);
    setImage(val);
  };
  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        Image Settings
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="image provider by category" size="small">
          <TableBody>
            {data.length > 0 ? (
              data.map((v, i) => (
                <TableRow key={i}>
                  <TableCell>{v.category.toUpperCase()}</TableCell>
                  <TableCell>
                    <DialogImageBox
                      image={v.logo}
                      onChangeValue={onChangeValueHandler}
                      onImageChange={onImageChangeHandler}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>Not Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProviderImageCategory;
