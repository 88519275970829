import { Grid, TextField, Button } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { changePassword } from '../api/auth';
import _ from 'lodash';

let schema = yup.object().shape({
  new_password: yup.string().required(),
  old_password: yup.string().required(),
});

const ChangePasswordContent = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (values) => {
    changePassword(values)
      .then((data) => {
        if (data?.is_success) {
          toast.success('Password is Changed');
          reset();
        } else {
          toast.error('error');
        }
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="old_password"
            defaultValue=""
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="password"
                label="Old Password"
                error={!!errors?.old_password}
                {...field}
                helperText={errors?.old_password?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="new_password"
            defaultValue=""
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="password"
                label="New Password"
                error={!!errors?.new_password}
                {...field}
                helperText={errors?.new_password?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid container item justify="flex-end">
          <Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePasswordContent;
