import { Button, Grid, TextField, makeStyles, Container, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import xtype from 'xtypejs';
import { format } from 'date-fns';
import { createMaintenance } from '../../../api/cms/provider';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

// import { Grid, TextField, Button, Container, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

let schema = yup.object().shape({
  announcement_th: yup.string().required(),
  announcement_en: yup.string(),
});

const CreateMaintenanceForm = () => {
  // const [selectedStartDate, handleDateChange] = useState(moment());
  const [selectedStartDate, handleDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(new Date());

  let id = useParams();
  let history = useHistory();

  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (values) => {
    console.log('values : ', values);
    console.log('onSubmit ');

    var formatedStartDate = format(selectedStartDate, "yyyy-MM-dd'T'HH:mm:00xxx");
    var formatedEndDate = format(selectedEndDate, "yyyy-MM-dd'T'HH:mm:00xxx");

    console.log('formattedDate : ', formatedStartDate);
    const payload = {
      announcement: values.announcement,
      reference_id: id.providerId,
      maintenance_type: 'provider',
      start_maintenance: formatedStartDate,
      end_maintenance: formatedEndDate,
    };

    console.log('payload : ', payload);

    createMaintenance(payload)
      .then((data) => {
        console.log('data : ', data);
        if (data?.code == 0) {
          toast.success('create success');
          history.goBack();
        } else {
          toast.error('error');
        }
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <Typography variant="h6" gutterBottom component="div">
        Maintenance
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="announcement_th"
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  setAnnouncement="value"
                  id="announcement"
                  label="Announcement (ไทย)"
                  autoFocus
                  error={!!errors?.announcement}
                  {...field}
                  helperText={errors?.announcement?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="announcement_en"
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  setAnnouncement="value"
                  id="announcement"
                  label="Announcement (Global)"
                  autoFocus
                  error={!!errors?.announcement}
                  {...field}
                  helperText={errors?.announcement?.message}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              format="yyyy-MM-dd HH:mm"
              label="Start Maintenance "
              showTodayButton={true}
              allowKeyboardControl={true}
              value={selectedStartDate}
              onChange={handleDateChange}
              onError={console.log}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              format="yyyy-MM-dd HH:mm"
              label="End Maintenance "
              showTodayButton={true}
              allowKeyboardControl={true}
              value={selectedEndDate}
              onChange={handleEndDateChange}
              onError={console.log}
            />
          </Grid>
          <Grid container item justify="flex-end">
            <Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
                className={classes.submit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CreateMaintenanceForm;
