import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import { updateProviderById, getProviderById } from '../../../api/cms/provider';
import { LoadingBox } from '../../../components/LoadingBox';
import ProviderInfo from '../../../components/admin/provider/ProviderInfo';
import useSWR from 'swr';
import {
  Box,
  Switch,
  FormControlLabel,
  Button,
  Typography,
  Tabs,
  Tab,
  Grid,
} from '@material-ui/core';
import { Link, useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { useState, useMemo } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import GameListTable from '../../../components/admin/provider/GameListTable';
import ProviderImageCategory from '../../../components/admin/provider/ProviderImageCategory';
import { toast } from 'react-toastify';
import { Add } from '@material-ui/icons';
import _ from 'lodash';
import ProviderAffiliateSetting from 'components/admin/provider/AffiliateSetting';
import ProviderMaxBetSetting from 'components/admin/provider/ProviderMaxBetSetting';

const ProviderInfoPage = () => {
  let { providerId } = useParams();
  let location = useLocation();
  const [providerActive, setProviderActive] = useState(false);
  const [providerAffilates, setProviderAffiliates] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const { data: providerInfo, error: errorProvider } = useSWR(
    ['providerById', providerId],
    (url, providerId) => getProviderById(providerId),
    { revalidateOnFocus: false, refreshInterval: 0, shouldRetryOnError: false },
  );

  if (errorProvider) toast.error('error');

  const ProviderInfoContent = useMemo(() => {
    if (!providerInfo && !errorProvider) return <LoadingBox />;
    if (providerInfo) {
      setProviderActive(providerInfo.is_active);
      setProviderAffiliates(providerInfo.affiliate_settings);
      return <ProviderInfo providerInfo={providerInfo} />;
    }
  }, [providerInfo]);

  const onChangeActive = (is_active) => {
    updateProviderById(providerId, { is_active })
      .then(() => {
        setProviderActive(is_active);
        toast.success('updated');
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  const onTabChange = (event, newTab) => {
    setTabValue(newTab);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  };

  // const StyledTableRow = withStyles((theme) => ({
  //   root: {
  //     '&:nth-of-type(odd)': {
  //       backgroundColor: theme.palette.action.hover,
  //     },
  //   },
  // }))(TableRow);

  let match = useRouteMatch();

  return (
    <ApplicationLayout>
      <Grid>
        <Breadcrumbs
          breadcrumbNameAddOn={{ [`${location.pathname}`]: providerInfo?.provider_name }}
        />
        <Tabs value={tabValue} onChange={onTabChange}>
          <Tab label="General" value={0} {...a11yProps(0)} />
          <Tab label="Affiliate / Category" value={1} {...a11yProps(1)} />
          {providerInfo?.category.includes('Live Casino') ? (
            <Tab label="Min/Max Bet Limit" value={2} {...a11yProps(2)} />
          ) : null}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom component="div">
              Provider Info {providerInfo ? `(${providerInfo?.provider_name})` : null}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={providerActive}
                  onChange={(event) => {
                    onChangeActive(event.target.checked);
                  }}
                />
              }
              label="Active"
            />
          </Box>
          {ProviderInfoContent}
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1}>
              <Typography variant="h6" gutterBottom component="div">
                Game List
              </Typography>
            </Box>
            <Box style={{ padding: '0 20px' }}>
              <Link to={`${match.url}/game/new`}>
                <Button variant="contained" color="primary" endIcon={<Add />}>
                  New Game
                </Button>
              </Link>
            </Box>
          </Box>
          <Grid item xs={12}>
            {providerAffilates.length > 0
              ? providerAffilates.map((v, i) => (
                  <GameListTable
                    providerId={providerId}
                    categoryName={v.category}
                    config={providerInfo.configs.find((cfg) => cfg.category_code == v.category)}
                    key={i}
                    order={i}
                  />
                ))
              : null}
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid item xs={12}>
            <ProviderAffiliateSetting data={providerAffilates} />
          </Grid>
          <Grid item xs={12}>
            <ProviderImageCategory data={providerAffilates} />
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Grid item xs={12}>
            <ProviderMaxBetSetting data={providerInfo} />
          </Grid>
        </TabPanel>
      </Grid>
    </ApplicationLayout>
  );
};

export default ProviderInfoPage;
