import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Checkbox,
  TableHead,
  Grid,
  Button,
  Box,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GameModal from '../game/GameModal';
import Link from '@material-ui/core/Link';
import Thead from '../../../components/table/Thead';
import { useState } from 'react';
import {
  getGameById,
  updateGameVerified,
  deleteGame,
  getGameVerified,
  deleteGameVerified,
} from 'api/cms/game';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import _ from 'lodash';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  large: {
    width: '80px',
    padding: '0 10px',
  },
  spacingBtn: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    margin: '20px 0',
  },
  spacingLink: {
    paddingInline: theme.spacing(2),
    cursor: 'pointer',
  },
  middleAlign: {
    verticalAlign: 'middle',
  },
}));

const GameVerifiedListTable = ({ games = [], cb }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    image: '/images/provider/default.jpeg',
  });
  const [open, setOpen] = useState(false);
  const [game, setGame] = useState();
  const [selected, setSelected] = useState([]);
  const match = useRouteMatch();
  const swal = withReactContent(Swal);

  const handleOpenGameDetail = (gameId) => {
    setOpen(true);
    console.log('handleOpenGameDetail: ->', game);
    getGameById(gameId).then((data) => setGame(data));
  };

  const handleCloseGameDetail = () => {
    setOpen(false);
    setGame({});
  };

  const handleClick = (event, name) => {
    console.log(name);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleApprove = (item) => {
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to approve ${item.game_name}`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          console.log('isConfirm');
          const data = { game_ids: [item.game_id] };
          updateGameVerified(data)
            .then((res) => {
              if (res?.code == 0) {
                getGameVerified();
                cb(true);
                toast.success('approve successful');
              } else {
                toast.error(res.message, 'approve failed');
              }
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
    console.log(item);
  };

  const handleAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = games.map((g) => g.game_id);
      setSelected(newSelecteds);
      console.log(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const updateVerified = () => {
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to approve selected game`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((res) => {
        if (res.isConfirmed) {
          const data = { game_ids: selected };
          updateGameVerified(data).then((res) => {
            toast.success('approve successful');
            cb(true);
            // getGameVerified();
          });
        }
      });
    console.log('clicked: ', selected);
  };

  const deleteVerified = () => {
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to delete selected game`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const data = { game_ids: selected };
          deleteGameVerified(data)
            .then((res) => {
              if (res?.code === 0) {
                cb(true);
                toast.success('delete successful');
              } else {
                toast.error(res.message, 'failed');
              }
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
    console.log('deleteVerified: ', selected);
  };

  const handleDeleteGame = (item) => {
    swal
      .fire({
        title: `Are you sure`,
        text: `Do you want to delete ${item.game_name}`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteGame(item.game_id)
            .then((res) => {
              if (res?.code === 0) {
                cb(true);
                toast.success('delete successful');
              } else {
                toast.error(res.message, 'approve failed');
              }
            })
            .catch((error) => {
              toast.error(_.get(error, 'response.data.message', 'error'));
            });
        }
      });
    console.log('deleteGame: ', item);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <GameModal open={open} handleClose={handleCloseGameDetail} game={game} />
      <Grid container>
        <Paper className={classes.paper}>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length > 0 ? false : true}
            onClick={() => updateVerified()}
            className={classes.spacingBtn}
          >
            APPROVE
          </Button>
          <Button
            variant="contained"
            color="default"
            disabled={selected.length > 0 ? false : true}
            onClick={() => deleteVerified()}
          >
            DELETE
          </Button>
        </Paper>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="game table" size="small">
          <TableHead>
            <TableRow onClick={(event) => handleAllClick(event)}>
              <TableCell padding="checkbox">
                <Checkbox color="primary" />
              </TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {games.length > 0 ? (
              games.map((row, index) => {
                const isItemSelected = isSelected(row.game_id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    key={row.game_id}
                    selected
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => {
                          handleClick(event, row.game_id);
                        }}
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell style={{ width: 'auto', cursor: 'pointer' }}>
                      <Box className={classes.middleAlign}>
                        <img src={row.game_icon_url || state.image} className={classes.large} />
                        {row.game_name} ({row.game_code})
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: 'auto' }}>{row.game_category}</TableCell>
                    <TableCell align="left">
                      <Link onClick={() => handleApprove(row)} className={classes.spacingLink}>
                        Approve
                      </Link>
                      <Link
                        onClick={() => handleOpenGameDetail(row.game_id)}
                        className={classes.spacingLink}
                      >
                        View
                      </Link>
                      <Link
                        href={`${match.url}/game/${row.game_id}/edit`}
                        className={classes.spacingLink}
                      >
                        Edit
                      </Link>
                      <Link onClick={() => handleDeleteGame(row)} className={classes.spacingLink}>
                        Delete
                      </Link>
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  No Game Verified Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GameVerifiedListTable;
