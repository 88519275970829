import { Grid, Box } from '@material-ui/core';
import React from 'react';
import DateRangePickerBS from '../DateRangePickerBS';

const SummaryDateRangeContent = ({ onChange, value, maxDate, minDate }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} flexWrap="wrap" flexDirection="row" display="flex">
          <DateRangePickerBS
            onChange={onChange}
            value={value}
            setMaxDate={maxDate}
            setMinDate={minDate}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SummaryDateRangeContent;
