import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';

const DialogPopup = (props) => {
  const [open, setOpen] = useState();

  useEffect(() => {
    console.log(props);
    setOpen(props.handleOpen);
  });

  const handleClickClose = () => {
    console.log('close');
    setOpen(false);
    props.handleClose(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="popup-dialog-title"
        aria-describedby="popup-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="popup-dialog-description">
            {props.info ? (
              <Table>
                <TableBody>
                  {Object.keys(props.info).map((v, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{v}</TableCell>
                        <TableCell>{props.info[v]}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <></>
            )}
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClickClose} color={'primary'} variant={'contained'}>
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogPopup.propTypes = {
  info: PropTypes.Object,
  handleOpen: PropTypes.Boolean,
  handleClose: PropTypes.Boolean,
};

export default DialogPopup;
