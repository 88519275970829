import React from 'react';
import {
  Avatar,
  Paper,
  Button,
  TextField,
  Box,
  Link,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '../store/user';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { login } from '../api/auth/login';
import { toast } from 'react-toastify';
import _ from 'lodash';

let schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const [user, setUser] = useUser();
  // let history = useHistory();
  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: '/' } };

  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = (values) => {
    console.log(values);
    let payload = {
      username: values.username,
      password: values.password,
    };
    login(payload)
      .then(async (data) => {
        if (data.code == 0) {
          await setUser(data.data);
          // history.push(from);
          toast.success('Login Success');
        }
      })
      .catch((error) => {
        // error.response.data
        console.log('error', error);
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
        </Box>
        <Paper className={classes.paper} p={2}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="username"
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Username"
                  autoComplete="username"
                  autoFocus
                  error={!!errors?.username}
                  {...field}
                  helperText={errors?.username?.message}
                />
              )}
              control={control}
            />
            <Controller
              name="password"
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  error={!!errors?.password}
                  {...field}
                  helperText={errors?.password?.message}
                  autoComplete="current-password"
                />
              )}
              control={control}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
