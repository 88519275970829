import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from '../store/user';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const [{ isLogin }] = useUser();
  // console.log('isLogin', isLogin);

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      component={(routeProps) =>
        isLogin && restricted ? <Redirect to="/" /> : <Component {...routeProps} />
      }
    />
  );
};

export default PublicRoute;
