import {
  Button,
  Grid,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  FormControl,
  Avatar,
  makeStyles,
  InputLabel,
} from '@material-ui/core';
import { Category } from '../../../utils/game_category';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createGame } from '../../../api/cms/game';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
// import { useHistory, Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

let schema = yup.object().shape({
  game_name: yup.string().required(),
  game_code: yup.string().required(),
  game_type: yup.string().required(),
  variant_type: yup.string().required(),
  category: yup.string(),
});

const CreateGameForm = () => {
  const history = useHistory();

  const [inputList, setInputList] = useState([{ key: '', description: '' }]);
  const [category, setcategory] = useState('');

  const [state, setState] = useState({
    is_disabled: false,
    is_top_picked: false,
    is_new_game: false,
    // image: '/images/provider/default.jpeg',
  });
  let id = useParams();

  const [image, setImage] = useState('/images/provider/default.jpeg');
  const [preview, setPreview] = useState('/images/provider/default.jpeg');

  const handleChangeImage = (e) => {
    console.log('handleChangeImage .... ');
    if (e.target.files.length > 0) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { description: '' }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const onSubmit = (values) => {
    let formData = new FormData(); //formdata object

    console.log('inputList : ', inputList);
    console.log('inputList.length : ', inputList.length);

    if (inputList.length > 0) {
      console.log('inputList.length > 0');
      let inputListObject = {};
      inputList.reduce((acc, post) => {
        let { key, description } = post;
        Object.assign(inputListObject, { ...acc, [key]: description });
      }, {});
      console.log('inputListObject : ', inputListObject);
      if (JSON.stringify(inputListObject) != '{"":""}') {
        console.log('inputListObject != undefined');
        console.log('JSON.stringify(inputListObject) : ', JSON.stringify(inputListObject));
        formData.append('details', JSON.stringify(inputListObject));
      } else {
        console.log('inputListObject == undefined');
      }
    }

    formData.append('game_name', values.game_name); //append the values with key, value pair
    formData.append('game_icon_url', image);
    formData.append('game_code', values.game_code);
    formData.append('game_type', values.game_type);
    formData.append('variant_type', values.variant_type);
    formData.append('is_top_picked', state.is_top_picked);
    formData.append('is_disabled', state.is_disabled);
    formData.append('is_new_game', state.is_new_game);
    formData.append('category', category);
    formData.append('provider_game_id', values.provider_game_id);

    if (values.order != undefined) {
      console.log('!= undrfind ', values.order);
      let order = parseInt(values.order, 10);
      formData.append('order', order);
    }

    createGame(formData, id.providerId)
      .then((data) => {
        console.log('data : ', data);
        if (data?.code == 0) {
          toast.success('create game success');
          // history.push('/provider_list/c4s7fp525tm1snnfl4jg');
          history.push(`/provider_list/${id.providerId}`);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <input
            accept="image/*"
            name="game_icon_url"
            type="file"
            id="game_icon_url"
            hidden
            onChange={handleChangeImage}
          />
          <label htmlFor="game_icon_url">
            <Avatar src={preview} className={classes.large} variant="rounded" />
          </label>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="game_name"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Game Name"
                type="text"
                autoFocus
                error={!!errors?.game_name}
                {...field}
                helperText={errors?.game_name?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="game_code"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Game Code"
                type="text"
                error={!!errors?.game_code}
                {...field}
                helperText={errors?.game_code?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="category"
            render={({ option }) => (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  variant="outlined"
                  fullWidth
                  label="Category"
                  value={category}
                  onChange={(event) => {
                    const name = event.target.value;
                    setcategory(name);
                  }}
                >
                  <option aria-label="None" value="category" selected />

                  {Category.map((value) => (
                    <option
                      // setcategory={value.category}
                      key={value.category}
                      value={value.category}
                      // value={value?.category_code}
                      onChange={(event) => {
                        console.log('event : ', event);
                        const category_code = event.target.value;
                        // const name = event.target.value;
                        setcategory(category_code);
                      }}
                      {...option}
                    >
                      {value.category}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="game_type"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Game Type"
                type="text"
                error={!!errors?.game_type}
                {...field}
                helperText={errors?.game_type?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="variant_type"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Variant Type"
                type="text"
                error={!!errors?.variant_type}
                {...field}
                helperText={errors?.variant_type?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="provider_game_id"
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Provider Id"
                type="text"
                {...field}
              />
            )}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="is_new_game"
            render={({ field: { value } }) => (
              <FormControlLabel
                label="New Game"
                control={
                  <Checkbox
                    name="is_new_game"
                    color="primary"
                    checked={value}
                    onChange={handleChange}
                  />
                }
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="is_top_picked"
            render={({ field: { value } }) => (
              <FormControlLabel
                label="Top Pick"
                control={
                  <Checkbox
                    name="is_top_picked"
                    color="primary"
                    checked={value}
                    onChange={handleChange}
                  />
                }
              />
            )}
            control={control}
          />
        </Grid>
        {state.is_top_picked ? (
          <Grid item xs={12} sm={6}>
            <Controller
              name="order"
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Order"
                  type="number"
                  error={!!errors?.order}
                  {...field}
                  helperText={errors?.order?.message}
                />
              )}
              control={control}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Controller
            name="is_disabled"
            render={({ field }) => (
              <FormControlLabel
                label="Disable"
                control={
                  <Checkbox
                    name="is_disabled"
                    checked={state.is_disabled}
                    color="primary"
                    onChange={handleChange}
                  />
                }
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <h2>Details </h2>
        </Grid>
        <Grid item xs={12}>
          {inputList.map((x, i) => {
            return (
              <div className="box" key={i}>
                <Grid container>
                  <Grid item md={3} sm={3} style={{ paddingRight: '2px' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="key"
                      value={x.key}
                      label="Key"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="description"
                      value={x.description}
                      label="Description"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} style={{ alignSelf: 'center' }}>
                    <div className="btn-box">
                      {inputList.length !== 1 && (
                        <Button
                          style={{ margin: '1rem', backgroundColor: '#e65a58' }}
                          className="mr10"
                          onClick={() => handleRemoveClick(i)}
                        >
                          Remove
                        </Button>
                      )}
                      {inputList.length - 1 === i && (
                        <Button
                          variant="contained"
                          // color="#59673e"
                          style={{ margin: '1rem', backgroundColor: '#8bc34a' }}
                          onClick={handleAddClick}
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
          className={classes.submit}
        >
          Save
        </Button>
      </Grid>
    </form>
  );
};

export default CreateGameForm;
