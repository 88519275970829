import Cell from './Cell';
import { formatNumber, isNegativeNumber } from '../../utils/number';
import classnames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';

const CustomerGroup = ({ data, disabledBg = false }) => {
  const bet_balance = parseFloat(data.bet_balance);
  const valid_bet_balance = parseFloat(data.valid_bet_balance);
  return (
    <>
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(bet_balance) ? 'text-red' : 'text-blue',
        )}
        text={formatNumber(bet_balance)}
      />
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(valid_bet_balance) ? 'text-red' : 'text-blue',
        )}
        text={formatNumber(valid_bet_balance)}
      />
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(data.win_loss_balance) ? 'text-red' : 'text-blue',
        )}
        text={formatNumber(data.win_loss_balance)}
      />
      <Cell
        align="center"
        className={classnames(
          !disabledBg && 'bg-table-secondary',
          isNegativeNumber(data.profit_loss_balance) ? 'text-red' : 'text-blue',
        )}
        text={formatNumber(data.profit_loss_balance)}
      />
    </>
  );
};

export default CustomerGroup;
